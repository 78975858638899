import { forwardRef, useImperativeHandle, useEffect, useRef, useState } from 'react'

import PropTypes from 'prop-types'

import { MarqueeContainer, MarqueeWrapper, MarqueeContent, MarqueeMain, MarqueeClone } from './style'

const Marquee = forwardRef(({ horizontal = false, text = '' }, ref) => {
  const containerRef = useRef(null)
  const wrapperRef = useRef(null)
  const contentRef = useRef(null)
  const mainRef = useRef(null)
  const [len, setLen] = useState(0)

  // PUBLIC METHODS
  useImperativeHandle(ref, () => ({
    containerRef,
    wrapperRef,
    contentRef,
    mainRef
  }))

  useEffect(() => {
    const x = horizontal ? containerRef.current.offsetWidth : containerRef.current.offsetHeight
    const y = mainRef.current.offsetWidth

    const calc = Math.ceil(x / y) || 0

    setLen(calc + 1)
  }, [])

  return (
    <MarqueeContainer ref={containerRef} className={`${horizontal && 'horizontal'}`}>
      <MarqueeWrapper ref={wrapperRef}>
        <MarqueeContent ref={contentRef}>
          <MarqueeMain ref={mainRef}>
            <span dangerouslySetInnerHTML={{ __html: text }} />
            &nbsp;
          </MarqueeMain>
          {[...Array(len)].map((i, k) => (
            <MarqueeClone key={k} index={k}>
              <span dangerouslySetInnerHTML={{ __html: text }} />
              &nbsp;
            </MarqueeClone>
          ))}
        </MarqueeContent>
      </MarqueeWrapper>
    </MarqueeContainer>
  )
})

Marquee.displayName = 'Marquee'

Marquee.propTypes = {
  horizontal: PropTypes.bool,
  text: PropTypes.string
}

export default Marquee
