import { gsap } from '@vendor/gsap'

import { animationTitleAction, animationTitleInit } from '@animations/title'
import { animationTextAction, animationTextInit } from '@animations/text'
import CustomEase from '@vendor/gsap/CustomEase'

export const animationInit = ({ titleRef, visualRef, textRef, listRef }, isDesktop) => {
  // TITLE ANIMATION
  animationTitleInit(titleRef, isDesktop)
  // TEXT ANIMATION
  textRef.current && animationTextInit(textRef, isDesktop)
  // VISUAL ANIMATION
  const visual = visualRef.current
  if (visual) {
    const background = visual.querySelector('.background')
    const image = visual.querySelector('.image')

    gsap.set(background, { x: '100%' })
    gsap.set(image, { x: isDesktop ? '100%' : '200%', opacity: 0 })
  }
  // LIST ANIMATION
  const list = listRef.current
  if (list) {
    const items = list?.querySelectorAll('.item')

    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      const city = item?.querySelector('.city')

      gsap.set(item, { y: '100%', height: '150px', opacity: 0 })
      gsap.set(city, { x: '100%' })
    }
  }
}

export const animationAction = ({ titleRef, visualRef, textRef, listRef }, isDesktop) => {
  const tl = gsap.timeline({
    defaults: { duration: 2.7, ease: 'power4.out' }
  })

  tl.add('start')

  // TITLE ANIMATION
  animationTitleAction(titleRef, isDesktop)
  // TEXT ANIMATION
  textRef.current && animationTextAction(textRef, isDesktop)
  // VISUAL ANIMATION
  const visual = visualRef.current
  if (visual) {
    const background = visual.querySelector('.background')
    const image = visual.querySelector('.image')
    let ease = CustomEase.create(
      'custom',
      'M0,0 C0.29,0 0.32,0.096 0.34,0.124 0.386,0.19 0.452,0.362 0.484,0.502 0.504,0.592 0.545,0.698 0.6,0.8 0.628,0.834 0.704,1 1,1 '
    )

    tl.to(
      background,
      {
        duration: isDesktop ? 1 : 0.75 / 2,
        delay: 0.5,
        x: 0,
        ease
      },
      'start'
    )

    tl.add('step1')

    tl.to(
      background,
      {
        duration: isDesktop ? 1 : 0.75,
        x: '-100%',
        ease
      },
      'step1'
    )

    tl.to(
      image,
      {
        duration: isDesktop ? 1.25 : 1.25,
        opacity: 1,
        ease
      },
      `step1-=${isDesktop ? 0.1875 : 0.1875}`
    )
    tl.to(
      image,
      {
        duration: isDesktop ? 1 : 0.75,
        x: '0',
        ease
      },
      `step1-=${isDesktop ? 0.1875 : 0.1875}`
    )
  }
  // LIST ANIMATION
  const list = listRef.current
  if (list) {
    const items = list?.querySelectorAll('.item')

    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      const city = item?.querySelector('.city')

      tl.to(
        item,
        {
          duration: 1.3,
          y: 0,
          opacity: 1,
          height: 'auto',
          ease: CustomEase.create(
            'basic',
            'M0,0 C0.29,0 0.32,0.096 0.34,0.124 0.386,0.19 0.452,0.362 0.484,0.502 0.504,0.592 0.545,0.698 0.6,0.8 0.628,0.834 0.704,1 1,1 '
          )
        },
        'start'
      )
      tl.to(
        city,
        {
          duration: 1.3,
          x: 0,
          ease: CustomEase.create(
            'basic',
            'M0,0 C0.29,0 0.32,0.096 0.34,0.124 0.386,0.19 0.452,0.362 0.484,0.502 0.504,0.592 0.545,0.698 0.6,0.8 0.628,0.834 0.704,1 1,1 '
          )
        },
        'start'
      )
    }
  }
}
