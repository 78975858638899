import breakpoints from '@styles/breakpoints'
import theme from '@styles/theme'

export const mediaMax = Object.keys(breakpoints)
  .map((key) => [key, breakpoints[key]])
  .reduce((prev, [key, breakpoint]) => {
    prev[key] = `@media (max-width: ${breakpoint}px)`
    return prev
  }, {})

export const mediaMin = Object.keys(breakpoints)
  .map((key) => [key, breakpoints[key]])
  .reduce((prev, [key, breakpoint]) => {
    prev[key] = `@media (min-width: ${breakpoint}px)`
    return prev
  }, {})

/**
 * @param value {number}
 * @param unit {string}
 * @returns {`calc(${number} * var(--${string}))`}
 */
export const cem = (value = 0, unit = 'cem') => {
  return `calc(${value} * var(--${unit}))`
}

/**
 * Default Title Mixins
 *
 * @param color {string}
 * @param size {string}
 * @param lh {string}
 * @param desktop {object}
 * @returns {"
    font-size: 13rem;
    font-family: Lacoste;
    color: #285f41;
    line-height: 12.2rem;
  "}
 */
export const sectionTitle = ({ color = theme.colors.green, size = 13, lh = 12.2 }, desktop = {}) => {
  return `
    font-size: ${cem(size)};
    font-family: ${theme.fonts.lacoste};
    color: ${color};
    line-height: ${cem(lh)};

    ${mediaMin.sm} {
      font-size: ${cem(desktop?.size || size)};
      color: ${desktop?.color || color};
      line-height: ${cem(desktop?.lh || lh)};
    }
  `
}

/**
 * Default Text Mixins
 *
 * @param color {string}
 * @param size {string}
 * @param lh {string}
 * @param desktop {object}
 * @returns {"
    font-size: 5rem;
    font-family: Lacoste;
    color: #285f41;
    line-height: 6.8rem;
  "}
 */
export const sectionText = ({ color = theme.colors.green, size = 5, lh = 6.8 }, desktop = {}) => {
  return `
    font-size: ${cem(size)};
    font-family: ${theme.fonts.lacoste};
    color: ${color};
    line-height: ${cem(lh)};

    ${mediaMin.sm} {
      font-size: ${cem(desktop?.size || size)};
      color: ${desktop?.color || color};
      line-height: ${cem(desktop?.lh || lh)};
    }
  `
}

/**
 * Default Grid
 *
 * @returns {string}
 */
export const sectionGrid = () => {
  return `
    width: 100%;
    padding: 0 ${cem(9.2)};

    ${mediaMin.lg} {
      padding: 0 ${cem(17.4)};
    }
  `
}

/**
 * Default Sub Grid
 *
 * @returns {string}
 */
export const sectionSubGrid = () => {
  return `
    width: 100%;
    padding: 0 ${cem(16)};
  `
}

/**
 * Default Button Container
 *
 * @returns {string}
 */
export const sectionButtonContainer = () => {
  return `
    width: 100%;
    padding: 0 ${cem(15.4)};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: ${cem(1.4)};
  `
}
