import { gsap } from '@vendor/gsap'
import CustomEase from '@vendor/gsap/CustomEase'

export const animationInit = ({ titleRef, logoRef, listRef, containerRef }) => {
  // CONTAINER ANIMATION
  const container = containerRef.current
  gsap.set(container, { '--title-bar': '0%' })

  // TITLE ANIMATION
  const title = titleRef.current
  gsap.set(title, { scale: 2, opacity: 0 })

  // LIST ANIMATION
  const list = listRef.current
  const items = list.querySelectorAll('.item')
  items.forEach((i, k) => {
    gsap.set(i, { y: `${50 * k + 100}%`, opacity: 0 })
  })

  // LOGO ANIMATION
  const logo = logoRef.current
  gsap.set(logo, { opacity: 0, x: '-50%' })
}

export const animationAction = ({ titleRef, logoRef, listRef, containerRef }) => {
  const tl = gsap.timeline({
    defaults: {
      duration: 1.2,
      ease: CustomEase.create('basic', 'M0,0 C0.142,0.43 0.116,0.564 0.372,0.822 0.564,1.002 0.818,1.001 1,1 ')
    }
  })

  tl.add('start')

  // CONTAINER ANIMATION
  const container = containerRef.current
  tl.to(container, { '--title-bar': '100%' }, 'start')

  // TITLE ANIMATION
  const title = titleRef.current
  tl.to(title, { scale: 1, opacity: 1 }, 'start')

  // LIST ANIMATION
  const list = listRef.current
  const items = list.querySelectorAll('.item')
  tl.to(items, { y: '0%', opacity: 1 }, 'start')

  // LOGO ANIMATION
  const logo = logoRef.current
  tl.to(logo, { opacity: 1, x: 0 }, 'start')
}
