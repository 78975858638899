import styled from '@emotion/styled'
import { mediaMin, cem } from '@styles/mixins'

import { StoryNavContainer, StoryNavItem } from '@components/Story/atoms/Nav/style'

export const StorySimpleContainer = styled.div`
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${mediaMin.sm} {
    height: calc(var(--rh) - var(--header-height-with-all, 0vh));
    z-index: 1;
  }
`

export const StorySimpleInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  background-color: ${({ theme }) => theme.colors.green};
  overflow: hidden;

  ${mediaMin.sm} {
    z-index: 1;

    &:before,
    &:after {
      z-index: -2;
      content: '';
      position: absolute;
      width: 50%;
      height: 100%;
      top: 0;
    }

    &:before {
      left: 0;
      background-color: var(--page-color-left, ${({ theme }) => theme.colors.lightGreen});
    }

    &:after {
      left: 50%;
      background-color: var(--page-color-right, ${({ theme }) => theme.colors.green});
    }
  }
`

export const StorySimpleHeader = styled.div`
  width: 100%;
  padding: ${cem(7.8)} ${cem(6.3)} 0;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;

  ${mediaMin.sm} {
    padding: ${cem(5.48)} ${cem(4.6)} 0;
    left: 0;
    transform: none;

    ${StoryNavContainer} {
      gap: ${cem(0.5)};
      justify-content: flex-start;
    }

    ${StoryNavItem} {
      width: ${cem(8.3)};
      height: ${cem(0.3)};
    }
  }
`

export const StorySimpleLogo = styled.img`
  display: none;
  position: absolute;
  top: 50%;
  left: 75%;
  width: 12%;
  opacity: 1;
  transform: translate(-50%, -50%);
  z-index: -1;

  ${mediaMin.sm} {
    display: initial;
    left: 50%;
    width: 100%;
    opacity: 0.2;
  }

  ${mediaMin.lg} {
    left: 75%;
    width: 12%;
    opacity: 1;
  }
`

export const StorySimpleContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  ${mediaMin.sm} {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    height: 89%;
    box-shadow: 0 ${cem(3.8)} ${cem(8.2)} 0px rgba(0, 0, 0, 0.21);
  }

  ${mediaMin.lg} {
    left: 25%;
    width: auto;
    aspect-ratio: 9 / 16;
  }
`

export const StorySimpleWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
`

export const StorySimpleItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
`

export const StorySimpleItemVideo = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
`

export const StorySimpleItemVideoMedia = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
`
