import styled from '@emotion/styled'
import { mediaMin, sectionGrid, sectionSubGrid, sectionText, sectionTitle, cem } from '@styles/mixins'

import { Link } from 'react-router-dom'

import { ReactComponent as ArrowRight } from '@icons/arrow-right.svg'

export const BlockEditoContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};

  ${mediaMin.sm} {
    padding: ${cem(10)} 0 ${cem(10)};
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${cem(10.4)};

  ${mediaMin.sm} {
    display: grid;
    gap: ${cem(3)} 0;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(8, 1fr);
  }
`

export const BlockEditoTitle = styled.div`
  ${sectionTitle({}, { size: 8, lh: 7.5 })};
  ${sectionGrid};
  padding-top: ${cem(15)};

  ${mediaMin.sm} {
    padding-top: 0;
    grid-column-start: 1;
    grid-column-end: 2;

    grid-row-start: 1;
    grid-row-end: 5;

    align-self: end;
  }

  ${mediaMin.lg} {
    padding-top: ${cem(0)};
    grid-column-start: 1;
    grid-column-end: 2;

    grid-row-start: 1;
    grid-row-end: 5;

    align-self: end;
  }
`

export const BlockEditoVisual = styled.div`
  width: 100%;
  height: 40vh;
  overflow: hidden;
  position: relative;

  ${mediaMin.sm} {
    grid-column-start: 2;
    grid-column-end: 3;

    grid-row-start: 1;
    grid-row-end: 9;

    height: 40vh;
  }

  ${mediaMin.lg} {
    height: 90vh;
  }
`

export const BlockEditoBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: ${({ theme }) => theme.colors.green};
`

export const BlockEditoImage = styled.img`
  width: 100%;
  height: 100%;
  z-index: 2;
  position: relative;
  object-fit: cover;
`

export const BlockEditoText = styled.div`
  ${sectionText({}, { size: 2.2, lh: 3.5 })};
  ${sectionGrid};
  ${sectionSubGrid};

  ${mediaMin.sm} {
    padding: 0 ${cem(9.2)};
    grid-column-start: 1;
    grid-column-end: 2;

    grid-row-start: 5;
    grid-row-end: 9;

    align-self: start;
  }

  ${mediaMin.lg} {
    padding: 0 ${cem(17.4)};
  }
`

export const BlockEditoList = styled.div`
  display: flex;
  flex-direction: column;
  ${sectionGrid};

  ${mediaMin.sm} {
    margin-top: ${cem(5)};
  }
`

export const BlockEditoItem = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${cem(4.5)} 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.green};
  text-decoration: none;
  padding-right: ${cem(10)};

  &:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.colors.green};
  }

  ${mediaMin.sm} {
    padding: ${cem(3)} 0;
    padding-right: ${cem(5)};
  }
`

export const BlockEditoItemImage = styled.img`
  height: ${cem(8)};
  object-fit: contain;

  ${mediaMin.sm} {
    width: ${cem(6.2)};
    height: auto;
  }
`

export const BlockEditoItemContainer = styled.div`
  display: flex;
  align-items: center;
`

export const BlockEditoItemTitle = styled.h4`
  ${sectionTitle({ size: 6, lh: 6.9 }, { size: 3.4, lh: 3.5 })};
  margin-left: ${cem(5.1)};

  ${mediaMin.sm} {
    margin-left: ${cem(2.5)};
  }
`

export const BlockEditoItemCity = styled.div`
  ${sectionText({ size: 4, lh: 6.9 }, { size: 2.3, lh: 3.8 })};
  white-space: nowrap;
  color: ${({ textColor }) => textColor};
  padding: 0 ${cem(2)};
  //margin-left: ${cem(13.6)};
  margin-left: 0;
  outline: 1px solid ${({ theme }) => theme.colors.green};
  background-color: ${({ bgColor }) => bgColor};

  ${mediaMin.sm} {
    padding: 0 ${cem(1.5)};
    color: ${({ textColor }) => textColor};
  }
`

export const BlockEditoItemCityArrow = styled(ArrowRight)`
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-100%, -50%);
  height: ${cem(4.3)};
  transition: transform 0.3s ease-out;

  ${mediaMin.sm} {
    height: ${cem(2.5)};
  }
`

export const BlockEditoWrapper = styled(Wrapper)`
  &.hasNoText {
    ${mediaMin.sm} {
      ${BlockEditoTitle} {
        grid-column-start: 1;
        grid-column-end: 2;

        grid-row-start: 1;
        grid-row-end: 9;

        align-self: center;
      }
    }
  }

  &.hasNoImage {
    & > div:last-of-type {
      padding-bottom: ${cem(20)};
    }

    ${mediaMin.sm} {
      & > div:last-of-type {
        padding-bottom: 0;
      }

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      gap: 0;

      ${BlockEditoTitle} {
        grid-column-start: 1;
        grid-column-end: 2;

        grid-row-start: 1;
        grid-row-end: 2;

        align-self: end;
      }
      ${BlockEditoText} {
        grid-column-start: 2;
        grid-column-end: 3;

        grid-row-start: 1;
        grid-row-end: 2;

        align-self: end;
      }
      ${BlockEditoList} {
        grid-column-start: 2;
        grid-column-end: 3;

        grid-row-start: 2;
        grid-row-end: 3;

        align-self: end;
      }
    }
  }
`
