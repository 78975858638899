import styled from '@emotion/styled'
import { cem, mediaMin } from '@styles/mixins'

export const FullMediaContainer = styled.div`
  width: 100%;
  height: calc(var(--rh) - var(--header-height-with-all, 0vh));
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${({ theme }) => theme.colors.green};
  overflow: hidden;

  ${mediaMin.sm} {
    height: calc(var(--rh) - var(--header-height-with-all, 0vh));
    z-index: 1;

    &:before,
    &:after {
      z-index: -2;
      content: '';
      position: absolute;
      width: 50%;
      height: 100%;
      top: 0;
    }

    &:before {
      left: 0;
      background-color: var(--page-color-left, ${({ theme }) => theme.colors.lightGreen});
    }

    &:after {
      left: 50%;
      background-color: var(--page-color-right, ${({ theme }) => theme.colors.green});
    }
  }
`

export const FullMediaLogo = styled.img`
  display: none;
  position: absolute;
  width: 70%;
  top: 50%;
  left: 50%;
  opacity: 0.1;
  transform: translate(-50%, -50%);
  z-index: -1;

  ${mediaMin.sm} {
    display: initial;
  }
`

export const FullMediaVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${mediaMin.sm} {
    width: 100%;
    height: 100%;
    // box-shadow: 0 ${cem(3.8)} ${cem(8.2)} 0px rgba(0, 0, 0, 0.21);
  }
`

export const FullMediaImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${mediaMin.sm} {
    width: 100%;
    height: 100%;
    // box-shadow: 0 ${cem(3.8)} ${cem(8.2)} 0px rgba(0, 0, 0, 0.21);
  }
`
