import styled from '@emotion/styled'
import { colors } from '@styles/theme'

import { mediaMin, sectionButtonContainer, sectionGrid, sectionTitle, cem } from '@styles/mixins'

export const PushProductContainer = styled.div`
  width: 100%;
  display: flex;
  padding-top: ${cem(15)};
  background-color: ${({ theme }) => theme.colors.green};

  ${mediaMin.sm} {
    padding-top: ${cem(7.8)};
    overflow: hidden;
  }
`

export const PushProductWrapper = styled.div`
  width: 100%;
  display: inline;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const PushProductTitle = styled.h2`
  ${sectionGrid};
  ${sectionTitle({ color: colors.white }, { color: colors.white, size: 8, lh: 8.5 })};
  margin-bottom: ${cem(8.2)};

  ${mediaMin.sm} {
    z-index: 2;
    position: relative;
    text-align: center;
    margin-bottom: ${cem(3.9)};
  }
`

export const PushProductContent = styled.div`
  ${sectionGrid};
  padding-top: ${cem(18)};
  padding-bottom: ${cem(15)};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  overflow: hidden;

  ${mediaMin.sm} {
    overflow: initial;
    padding-bottom: ${cem(3.9)};
    padding-top: ${cem(3.9)};
  }
`

export const PushProductProductBackground = styled.img`
  position: absolute;
  object-fit: cover;

  &:first-of-type {
    z-index: -1;
    width: ${cem(45.5)};
    height: ${cem(64.6)};
    left: ${cem(9.2)};
    top: 0;

    ${mediaMin.sm} {
      width: ${cem(31.1)};
      height: ${cem(55.8)};
      left: ${cem(-2.1)};
      top: -15%;
    }
  }

  &:nth-of-type(2) {
    z-index: -2;
    width: ${cem(38)};
    height: ${cem(37.65)};
    right: 0;
    top: 0;

    ${mediaMin.sm} {
      top: auto;
      width: ${cem(50.6)};
      height: ${cem(38.4)};
      left: ${cem(38.9)};
      bottom: 5%;
    }
  }

  &:nth-of-type(3) {
    z-index: -11;
    width: ${cem(39)};
    height: ${cem(66.2)};
    left: ${cem(-13)};
    bottom: 0;

    ${mediaMin.sm} {
      bottom: auto;
      top: -7.31%;
      width: ${cem(26.7)};
      height: ${cem(47.6)};
      left: ${cem(109.8)};
    }
  }

  &:nth-of-type(4) {
    z-index: -21;
    width: ${cem(45)};
    height: ${cem(68)};
    right: ${cem(10)};
    bottom: 0;

    ${mediaMin.sm} {
      top: auto;
      width: ${cem(25.4)};
      height: ${cem(44.7)};
      left: ${cem(144.7)};
      bottom: -7%;
      right: auto;
    }
  }

  &:nth-of-type(5) {
    display: none;

    ${mediaMin.sm} {
      display: initial;
      z-index: -11;
      top: 4.25%;
      width: ${cem(31.1)};
      height: ${cem(53.7)};
      left: ${cem(178.8)};
    }
  }
`

export const PushProductProductImage = styled.img`
  width: 90%;

  ${mediaMin.sm} {
    width: 30%;
  }
`

export const PushProductProduct = styled.div`
  ${sectionGrid};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${cem(1.2)};
  margin-top: ${cem(8.2)};

  ${mediaMin.sm} {
    margin-top: ${cem(3.9)};
  }
`

export const PushProductProductName = styled.h3`
  font-size: ${cem(5)};
  letter-spacing: 0.2em;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.lacoste};
  text-transform: uppercase;
  text-align: center;

  ${mediaMin.sm} {
    font-size: ${cem(2.5)};
  }
`

export const PushProductProductDesc = styled.span`
  font-size: ${cem(4)};
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.lacoste};
  text-align: center;

  ${mediaMin.sm} {
    font-size: ${cem(2)};
  }
`

export const PushProductActionWrapper = styled.div`
  ${sectionButtonContainer};
  padding: ${cem(7.6)} ${cem(10)} ${cem(13)};

  ${mediaMin.sm} {
    padding: ${cem(3.2)} ${cem(10)} ${cem(6.4)};
  }
`
