import { animationTitleAction, animationTitleInit } from '@animations/title'

export const animationInit = ({ titleRef }, isDesktop) => {
  // TITLE ANIMATION
  animationTitleInit(titleRef, isDesktop)
}

export const animationAction = ({ titleRef }, isDesktop) => {
  // TITLE ANIMATION
  animationTitleAction(titleRef, isDesktop)
}
