import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import HeroHome from '@components/HeroHome'
import BlockVideo from '@components/BlockVideo'
import StorySimple from '@components/Story/Simple'
import PushProduct from '@components/PushProduct'
import BlockEdito from '@components/BlockEdito'
import FullMedia from '@components/FullMedia'
import Selection from '@components/Selection'
import BlockSlider from '@components/BlockSlider'
import BlockStorySpecial from '@components/BlockStorySpecial'
import ListMeeting from '@components/ListMeeting'
import HeroMeeting from '@components/HeroMeeting'
import HeroCommunity from '@components/HeroCommunity'
import PushPopup from '@components/PushPopup'
import Marquee from '@components/Marquee'
import PopupStore from '@components/PopupStore'

import GTM from '@utils/GTM'
import shuffleArray from '@utils/shuffleArray'

import { Container } from '@components/PageBuilder/style'

const PageBuilder = ({ data = [] }) => {
  const [size, setSize] = useState(0)
  const { tag, content } = data

  const handleResize = () => {
    if (window.innerHeight !== 0 && window.innerHeight <= size) setSize(window.innerHeight)
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0
    })
    setSize(window.innerHeight)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const gtm = () => {
    if (GTM.init) {
      GTM.push({ page_data: null })
      GTM.push(GTM.getPageViewData(tag))
    } else {
      GTM.init = true
    }
  }

  useEffect(() => {
    gtm()
  }, [])

  const getComponent = (c) => {
    switch (c.type) {
      case 'HeroMeeting':
        return <HeroMeeting items={c?.items} video={c?.video} />
      case 'BlockEdito':
        return <BlockEdito image={c?.image} title={c?.title} text={c?.text} list={c?.list} />
      case 'StorySimple':
        return <StorySimple medias={c?.medias} />
      case 'Selection':
        return (
          <Selection
            title={c?.title}
            text={c?.text}
            label={c?.label}
            description={c?.description}
            image={c?.image}
            action={c?.action}
          />
        )
      case 'HeroCommunity':
        return (
          <HeroCommunity
            titleCategory={c?.titleCategory}
            titleName={c?.titleName}
            titleCity={c?.titleCity}
            backgroundColorTop={c?.backgroundColorTop}
            backgroundColorBottom={c?.backgroundColorBottom}
            textColorTop={c?.textColorTop}
            textColorBottom={c?.textColorBottom}
          />
        )
      case 'HeroHome':
        return <HeroHome logo={c?.logo} image={c?.image} titleTop={c?.titleTop} titleBottom={c?.titleBottom} />
      case 'BlockVideo':
        return <BlockVideo flags={c?.flags} title={c?.title} video={c?.video} action={c?.action} />
      case 'BlockStorySpecial':
        return <BlockStorySpecial title={c?.title} items={shuffleArray(c?.medias)} />
      case 'PushProduct':
        return (
          <PushProduct
            title={c?.title}
            image={c?.image}
            images={c?.images}
            product={c?.product}
            description={c?.description}
            action={c?.action}
          />
        )
      case 'FullMedia':
        return <FullMedia media={c?.media} />
      case 'BlockSlider':
        return <BlockSlider title={c?.title} text={c?.text} images={c?.images} />
      case 'ListMeeting':
        return <ListMeeting title={c?.title} list={c?.list} socials={c?.socials} />
      case 'PushPopup':
        return (
          <PushPopup
            title={c?.title}
            text={c?.text}
            action={c?.action}
            color={c?.color}
            backgroundColor={c?.backgroundColor}
          />
        )
      case 'PopupStore':
        return <PopupStore title={c?.title} stores={c?.stores} />
      default:
        break
    }
  }

  const children = content.map((c, i) => {
    return (
      <Container key={i} size={size}>
        {getComponent(c)}
        {c?.marquee && c?.marquee.length && <Marquee text={c?.marquee} />}
      </Container>
    )
  })

  return <>{children}</>
}

PageBuilder.propTypes = {
  data: PropTypes.object
}

export default PageBuilder
