import styled from '@emotion/styled'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: center;

  --rh: ${({ size }) => (size ? `${size}px` : '100vh')};
`
