import styled from '@emotion/styled'
import { cem, mediaMin, sectionGrid, sectionTitle } from '@styles/mixins'

export const BlockVideoContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
`

export const BlockVideoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const BlockVideoFlags = styled.div`
  ${sectionGrid};
  padding-right: 0;
  padding-top: ${cem(12)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ${cem(1.2)};

  ${mediaMin.sm} {
    padding-top: ${cem(7.5)};
    margin-bottom: ${cem(5)};
    gap: ${cem(0)};
  }

  ${mediaMin.lg} {
    padding-top: ${cem(7.5)};
    margin-bottom: ${cem(5)};
    gap: ${cem(0.6)};
  }
`

export const BlockVideoFlag = styled.img`
  width: ${cem(11.2)};
  height: auto;

  ${mediaMin.sm} {
    width: ${cem(6)};
    margin-right: ${cem(1.2)};
  }

  ${mediaMin.lg} {
    width: ${cem(6)};
  }
`

export const BlockVideoTitle = styled.h2`
  ${sectionTitle({}, { size: 7, lh: 7 })};
  ${sectionGrid};
  padding-top: ${cem(12)};
  padding-bottom: ${cem(12)};

  ${mediaMin.sm} {
    padding-top: ${cem(7.5)};
    padding-bottom: ${cem(7.5)};

    &.flags {
      padding-top: 0;
    }
  }
`

export const BlockVideoMedia = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaMin.lg} {
    height: calc(var(--rh) - var(--header-height-with-all, 0vh));
  }
`

export const BlockVideoMediaBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.colors.green};
`

export const BlockVideoPlayer = styled.video`
  width: 100%;
  object-fit: cover;
  height: calc(100% + 1px);

  ${mediaMin.sm} {
    height: 100%;
  }
`
