import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { gsap } from '@vendor/gsap'

import resolveAssetUrl from '@utils/resolveAssetUrl'
import checkTween from '@utils/checkTween'

import StoryNav from '@components/Story/atoms/Nav'

import { useAnimator } from '@hooks/useAnimator'
import { animationAction, animationInit } from '@components/Story/Simple/animations'
import useDeviceDetect from '@hooks/useDeviceDetect'

import {
  StorySimpleContainer,
  StorySimpleInner,
  StorySimpleLogo,
  StorySimpleContent,
  StorySimpleHeader,
  StorySimpleWrapper,
  StorySimpleItemImage,
  StorySimpleItemVideo,
  StorySimpleItemVideoMedia
} from '@components/Story/Simple/style'

import VideoControls from '@components/VideoControls'
import Observer from '@vendor/gsap/Observer'

const BASE_DURATION = 10000
const BASE_INTERVAL = 1000

const StorySimple = ({ medias = [] }) => {
  const { isDesktop } = useDeviceDetect()
  const containerRef = useRef()
  const innerRef = useRef()
  const navRef = useRef()
  const wrapperRef = useRef()
  const tweens = useRef({ anim: null, progress: null })
  const items = useRef([])
  const videos = useRef([])
  const [transition, setTransition] = useState(false)
  const [index, setIndex] = useState(0)
  const [paused, setPaused] = useState(true)
  const [duration, setDuration] = useState(BASE_DURATION)

  useAnimator({
    init: () => {
      animationInit({ innerRef, wrapperRef }, isDesktop)
    },
    action: () => {
      animationAction({ innerRef, wrapperRef }, isDesktop)
      setPaused(false)
    },
    ref: containerRef,
    limit: window.innerHeight / 2
  })

  useEffect(() => {
    if (medias[index]?.type === 'video' && !paused) {
      videos.current[index].play()
    }
  }, [index, paused])

  useEffect(() => {
    const observer = Observer.create({
      target: wrapperRef.current,
      type: 'touch',
      onLeft: () => {
        if (!transition) goTo(index + 1)
      },
      onRight: () => {
        if (!transition) goTo(index - 1)
      },
      preventDefault: false
    })

    return () => {
      observer.kill()
    }
  }, [index, paused, transition])

  useEffect(() => {
    if (!paused) {
      setTimer()
    }
  }, [index, paused])

  useEffect(() => {
    items.current.forEach((item, i) => {
      if (i === index) {
        gsap.set(item, { x: 0 })
      } else if (i > index) {
        gsap.set(item, { x: '100%' })
      } else if (i < index) {
        gsap.set(item, { x: '-100%' })
      }
    })

    return () => {
      checkTween(tweens, 'anim')
      checkTween(tweens, 'progress')
    }
  }, [])

  const setTimer = () => {
    checkTween(tweens, 'progress')

    const item = navRef?.current?.itemsRef?.current[index]

    if (item) {
      tweens.progress = gsap.timeline({
        onComplete: () => {
          if (!transition) goTo(index + 1, BASE_INTERVAL / BASE_INTERVAL)
        }
      })

      tweens.progress.fromTo(
        item,
        {
          width: 0
        },
        {
          width: '100%',
          duration: duration / 1000,
          ease: 'none'
        }
      )
    }
  }

  const goTo = (i, delay) => {
    const oldIndex = index
    const newIndex = i < medias.length ? Math.max(0, i) : 0

    if (oldIndex === newIndex) return

    if (!transition) animationFromTo(oldIndex, newIndex, delay)
  }

  const animationFromTo = (oldIndex, newIndex, delay = 0) => {
    setTransition(true)
    checkTween(tweens, 'anim')
    checkTween(tweens, 'progress')

    const animationDelay = delay
    const animationDuration = 1

    tweens.anim = gsap.timeline({
      delay: animationDelay,
      onComplete: () => {
        if (medias[newIndex]?.type === 'video') {
          setDuration(videos.current[newIndex].duration * 1000)
        } else {
          setDuration(BASE_DURATION)
        }

        Object.values(navRef?.current?.itemsRef?.current).forEach((j, key) => {
          if (key !== newIndex) {
            if (key > newIndex) {
              gsap.set(j, { width: 0 })
            } else {
              gsap.set(j, { width: '100%' })
            }
          }
        })

        setIndex(newIndex)
        setTransition(false)

        if (medias[oldIndex]?.type === 'video') {
          videos.current[oldIndex].pause()
          videos.current[oldIndex].currentTime = 0
        }
      }
    })

    const oldEl = items.current[oldIndex]
    const newEl = items.current[newIndex]

    gsap.set(oldEl, { zIndex: 0, x: 0 })
    gsap.set(newEl, {
      zIndex: 1,
      x: newIndex > oldIndex || (newIndex === 0 && oldIndex === medias.length - 1) ? '100%' : '-100%'
    })

    tweens.anim.add('trigger')

    const item = navRef?.current?.itemsRef?.current[index]
    if (item) {
      tweens.anim.to(
        item,
        {
          width: newIndex > oldIndex || (newIndex === 0 && oldIndex === medias.length - 1) ? '100%' : '0%',
          duration: animationDuration
        },
        'trigger'
      )
    }

    tweens.anim.to(
      oldEl,
      {
        x: newIndex > oldIndex || (newIndex === 0 && oldIndex === medias.length - 1) ? '-100%' : '100%',
        duration: animationDuration
      },
      'trigger'
    )
    tweens.anim.to(
      newEl,
      {
        x: 0,
        duration: animationDuration * (2 / 3)
      },
      'trigger'
    )
  }

  const handleClick = ({ clientX }) => {
    const el = wrapperRef.current
    const bounds = el.offsetLeft + el.offsetWidth
    goTo(clientX > bounds / 2 ? index + 1 : index - 1, 0)
  }

  const handleLoad = (i) => {
    if (index === i && i === 0) {
      setDuration(videos.current[i].duration * 1000)
    }
  }

  return (
    <StorySimpleContainer ref={containerRef}>
      <StorySimpleInner ref={innerRef}>
        <StorySimpleLogo src={resolveAssetUrl('/img/logo.svg')} alt={'lacoste-90-logo'} />
        <StorySimpleContent ref={wrapperRef} onClick={handleClick}>
          <StorySimpleHeader>
            <StoryNav ref={navRef} index={index} len={medias.length} />
          </StorySimpleHeader>
          <StorySimpleWrapper>
            {medias.map((m, k) =>
              m?.type === 'video' ? (
                <StorySimpleItemVideo key={k} ref={(ref) => (items.current[k] = ref)}>
                  <StorySimpleItemVideoMedia
                    src={resolveAssetUrl(m.src)}
                    poster={resolveAssetUrl(m.poster)}
                    alt={m.alt}
                    autoPlay={false}
                    muted={true}
                    playsInline={true}
                    loop={false}
                    onLoadedMetadata={() => handleLoad(k)}
                    ref={(ref) => (videos.current[k] = ref)}
                  />
                  {videos.current[k] && <VideoControls video={{ current: videos.current[k] }} />}
                </StorySimpleItemVideo>
              ) : (
                <StorySimpleItemImage
                  key={k}
                  src={resolveAssetUrl(m.src)}
                  alt={m.alt}
                  ref={(ref) => (items.current[k] = ref)}
                />
              )
            )}
          </StorySimpleWrapper>
        </StorySimpleContent>
      </StorySimpleInner>
    </StorySimpleContainer>
  )
}

StorySimple.propTypes = {
  medias: PropTypes.array.isRequired
}

export default StorySimple
