import gsap from '@/vendor/gsap'
import { SplitText } from '@/vendor/gsap/SplitText'

export const animationTextInit = (ref) => {
  const el = ref.current

  ref.split = new SplitText(el, { type: 'lines', linesClass: 'line' })

  gsap.set(ref.split.lines, { y: '100%', opacity: 0 })

  return ref
}

export const animationTextAction = (ref) => {
  const { split } = ref

  const tl = gsap.timeline({
    defaults: { duration: 1.5, ease: 'power4.out' }
  })

  tl.to(split.lines, { y: 0, opacity: 1, stagger: 0.1 })

  return tl
}
