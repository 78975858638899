import { ScrollRestoration } from 'react-router-dom'

import { Container } from '@pages/HomePage/style'
import PageBuilder from '@components/PageBuilder'

import { t } from '@locales'

export default function HomePage() {
  const data = t('PAGES.home')

  return (
    <Container>
      <ScrollRestoration
        getKey={(location) => {
          return location.pathname
        }}
      />
      <PageBuilder data={data} />
    </Container>
  )
}
