import { gsap } from '@vendor/gsap'

import { animationTitleAction, animationTitleInit } from '@animations/title'

export const animationInit = ({ titleRef }, isDesktop) => {
  // TITLE ANIMATION
  animationTitleInit(titleRef, isDesktop)
}

export const animationAction = ({ titleRef }, isDesktop) => {
  const tl = gsap.timeline({
    defaults: { duration: 2.7, ease: 'power4.out' }
  })
  tl.add('start')

  // TITLE ANIMATION
  animationTitleAction(titleRef, isDesktop)
}
