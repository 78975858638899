import styled from '@emotion/styled'

import { sectionButtonContainer, sectionGrid, sectionTitle, mediaMin, sectionText, cem } from '@styles/mixins'

export const SelectionContainer = styled.div`
  width: 100%;
  display: flex;
  padding-top: ${cem(15.2)};
  padding-bottom: ${cem(13.4)};
  overflow: hidden;

  ${mediaMin.sm} {
    padding: 0;
  }
`

export const SelectionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${mediaMin.sm} {
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    height: 43vh;
    gap: ${cem(3.5)} 0;
  }

  ${mediaMin.lg} {
    height: 87vh;
  }
`

export const SelectionTitle = styled.div`
  ${sectionGrid};
  ${sectionTitle({}, { size: 8, lh: 7.5 })};
  padding-bottom: ${cem(13)};

  ${mediaMin.sm} {
    width: 50%;
    padding-bottom: ${cem(0)};
  }

  ${mediaMin.lg} {
    padding-top: 0;
  }
`

export const SelectionVisual = styled.div`
  width: 100%;
  height: 43vh;
  position: relative;
  overflow: hidden;

  ${mediaMin.sm} {
    width: 50%;
    height: 100%;
    order: 2;
  }
`

export const SelectionBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: ${({ theme }) => theme.colors.green};
`

export const SelectionImage = styled.img`
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
  object-fit: cover;
`

export const SelectionContent = styled.div`
  ${sectionGrid};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: ${cem(1.8)};
  padding-top: ${cem(12)};

  ${mediaMin.sm} {
    width: 50%;
    align-items: flex-start;
    order: 1;
    gap: ${cem(2)};
    padding-top: ${cem(3)};
  }

  ${mediaMin.lg} {
    padding-top: 0;
  }
`

export const SelectionLabel = styled.h3`
  font-size: ${cem(5)};
  letter-spacing: 0.2em;
  color: ${({ theme }) => theme.colors.green};
  font-family: ${({ theme }) => theme.fonts.lacoste};
  text-align: center;
  text-transform: uppercase;
  margin-bottom: ${cem(2)};

  ${mediaMin.sm} {
    font-size: ${cem(2.5)};
    text-align: left;
  }
`

export const SelectionDescription = styled.div`
  ${sectionText({ size: 4 }, { size: 2.4, lh: 4 })}
  text-align: center;

  ${mediaMin.sm} {
    text-align: left;
  }
`

export const SelectionActionWrapper = styled.div`
  ${sectionButtonContainer};
  margin-top: ${cem(5)};
  padding: 0;

  ${mediaMin.sm} {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    margin-top: ${cem(2.5)};
  }
`
