import styled from '@emotion/styled'

import { mediaMin, sectionButtonContainer, sectionGrid, sectionTitle, cem } from '@styles/mixins'

import { ReactComponent as StoreLogo } from '@icons/store.svg'

export const PushPopupContainer = styled.div`
  --color: ${({ color }) => color};
  --backgroundColor: ${({ backgroundColor }) => backgroundColor};

  width: 100%;
  padding: ${cem(16)} 0;
  background-color: var(--backgroundColor);

  ${mediaMin.sm} {
    padding: ${cem(14)} 0;
  }
`

export const PushPopupWrapper = styled.div`
  ${sectionGrid};
  display: flex;
  flex-direction: column;

  ${mediaMin.sm} {
    flex-direction: row;
  }
`

export const PushPopupTitleWrapper = styled.div`
  margin-bottom: ${cem(15)};
  min-width: 50%;

  ${mediaMin.sm} {
    margin-bottom: 0;
    padding-right: ${cem(25)};
  }
`

export const PushPopupTitle = styled.h3`
  ${sectionTitle({ color: 'var(--color)' }, { size: 8, lh: 7.5 })};

  .line {
    white-space: nowrap;
  }
`

export const PushPopupBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const PushPopupBlockWrapper = styled.div`
  width: 100%;
  max-width: ${cem(80)};
  display: flex;
  flex-direction: column;
  padding: ${cem(4.6)} 0;
  margin: 0 ${cem(6.5)};
  border-top: 1px solid var(--color);
  border-bottom: 1px solid var(--color);

  ${mediaMin.sm} {
    width: 100%;
    // max-width: ${cem(70)};
    border-width: 2px;
    margin: 0;
  }
`

export const PushPopupBlockLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${cem(9.2)};
  height: ${cem(9.2)};
  margin-bottom: ${cem(5.6)};
`

export const PushPopupBlockImage = styled(StoreLogo)`
  width: 100%;
  height: 100%;
`

export const PushPopupBlockText = styled.h4`
  ${sectionTitle({ size: 7, lh: 8, color: 'var(--color)' }, { size: 4.5, lh: 4.8 })};
  margin-bottom: ${cem(1)};

  ${mediaMin.sm} {
    margin-bottom: 0;
    max-width: 100%;
  }
`

export const PushPopupAction = styled.div`
  ${sectionButtonContainer};
  text-align: center;
  padding: 0 ${cem(6.5)};
  margin-top: ${cem(10.4)};

  a {
    width: 100%;
    border: none;

    span {
      //color: var(--color) !important;
    }
  }

  ${mediaMin.sm} {
    align-items: flex-start;
    padding: 0;
    margin-top: ${cem(5)};

    a {
      width: auto;
      padding: ${cem(2)} ${cem(5.5)};
    }
  }
`
