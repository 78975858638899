import Controller from '@utils/Controller'

import { PAGE_VIEW, SELECT_PROMOTION } from '@utils/GTM/events'

class GTM {
  static init = false

  static push(event) {
    window?.dataLayer && window.dataLayer.push(event)
  }

  static getPageViewData(name) {
    return {
      event: PAGE_VIEW,
      page_data: {
        name,
        category: 'brand',
        subcategory: '90Y',
        environment: GTM.getEnv(),
        language: GTM.getLang(),
        country: GTM.getCountry()
      }
    }
  }

  static getSelectPromotionData(ecommerce) {
    return {
      event: SELECT_PROMOTION,
      ecommerce
    }
  }

  static getLang() {
    return window?.Lacoste?.siteprefs?.CURRENT_LANGUAGE
  }

  static getCountry() {
    return window?.Lacoste?.siteprefs?.ISO_COUNTRY_CODE
  }

  static getEnv() {
    if (process.env.NODE_ENV === 'development' || !Controller.get('env')) return 'dev'

    return Controller.get('env')
  }
}

export default GTM
