import { css } from '@emotion/react'
import emotionReset from 'emotion-reset'

import fonts from '@styles/fonts'
import { mediaMin } from '@styles/mixins'

import resolveAssetUrl from '@utils/resolveAssetUrl'

const styles = () => {
  return css`
    ${process.env.NODE_ENV === 'development' && emotionReset};

    #lacoste90-root {
      overflow-x: hidden;
      scroll-behavior: smooth;

      --cem: 0.8071vw;

      ${mediaMin.sm} {
        --cem: 0.7208vw;
      }

      ${mediaMin.lg} {
        --cem: 0.5208vw;
      }

      *,
      *::after,
      *::before {
        box-sizing: border-box;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-smoothing: antialiased;
      }

      [data-split] {
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
      }
    }

    @font-face {
      font-family: 'Lacoste';
      font-style: normal;
      font-weight: normal;
      src: local('Lacoste-Medium'), local('Lacoste-Medium'),
        url(${resolveAssetUrl(fonts.LacosteMedium)}) format('opentype');
      font-display: swap;
    }

    ${process.env.NODE_ENV === 'development' &&
    `
      @font-face {
        font-family: 'Archivo';
        font-style: normal;
        font-weight: normal;
        src: local('Archivo-Regular'), local('Archivo-Regular'),
          url(${resolveAssetUrl(fonts.ArchivoRegular)}) format('truetype');
        font-display: swap;
      }

      @font-face {
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 600;
        src: local('Archivo-Bold'), local('Archivo-Bold'),
          url(${resolveAssetUrl(fonts.ArchivoBold)}) format('truetype');
        font-display: swap;
      }
    `}
  `
}

export default styles
