import styled from '@emotion/styled'

import { cem, mediaMin } from '@styles/mixins'

export const VideoControlsContainer = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  left: 0;
  pointer-events: none;
`

export const VideoControlsWrapper = styled.div`
  width: 100%;
  height: ${cem(17.2)};
  padding: 0 ${cem(4)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  bottom: 0;
  left: 0;
  gap: ${cem(2)};

  ${mediaMin.lg} {
    height: ${cem(8.6)};
    padding: 0 ${cem(4)};
    gap: ${cem(2)};
  }
`

const VideoControlsButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${cem(9.6)};
  height: ${cem(9.6)};
  background-color: ${({ theme }) => theme.colors.dark2};
  border-radius: 50%;
  text-align: center;
  padding: 0;
  box-shadow: inset 0 0 0 ${cem(0.3)} ${({ theme }) => theme.colors.white},
    0 0 0 ${cem(0.4)} ${({ theme }) => theme.colors.dark2};
  pointer-events: initial;
  cursor: pointer;
  border: none;

  svg {
    width: 1em;
    height: 1em;
    line-height: 6.9em;
    font-size: ${cem(3.6)};
  }

  ${mediaMin.lg} {
    width: ${cem(3.2)};
    height: ${cem(3.2)};
    box-shadow: inset 0 0 0 ${cem(0.1)} ${({ theme }) => theme.colors.white},
      0 0 0 ${cem(0.4)} ${({ theme }) => theme.colors.dark2};

    svg {
      width: 1em;
      height: 1em;
      line-height: 2.3em;
      font-size: ${cem(1.2)};
    }
  }
`

export const VideoControlsSound = styled(VideoControlsButton)`
  svg {
    color: ${({ theme }) => `${theme.colors.white}00`};
    fill: ${({ theme }) => theme.colors.white};
  }

  &.active {
    svg {
      color: ${({ theme }) => theme.colors.white};
      fill: ${({ theme }) => `${theme.colors.white}00`};
    }
  }
`

export const VideoControlsPlay = styled(VideoControlsButton)`
  svg {
    fill: none;
    color: ${({ theme }) => theme.colors.white};
    stroke: ${({ theme }) => `${theme.colors.white}00`};
  }

  &.active {
    svg {
      color: ${({ theme }) => `${theme.colors.white}00`};
      stroke: ${({ theme }) => theme.colors.white};
    }
  }
`
