import { gsap } from '@vendor/gsap'

import { animationTitleAction, animationTitleInit } from '@animations/title'
import { animationTextAction, animationTextInit } from '@animations/text'
import CustomEase from '@vendor/gsap/CustomEase'

export const animationInit = ({ titleRef, textRef, imageRef }, isDesktop) => {
  // TITLE ANIMATION
  animationTitleInit(titleRef, isDesktop)
  // TEXT ANIMATION
  animationTextInit(textRef, isDesktop)
  // IMAGE ANIMATION
  const image = imageRef.current

  gsap.set(image, { y: '100%', opacity: 0 })
}

export const animationAction = ({ titleRef, textRef, imageRef }, isDesktop) => {
  const tl = gsap.timeline({
    defaults: {
      duration: 1.2,
      ease: CustomEase.create('basic', 'M0,0 C0.142,0.43 0.116,0.564 0.372,0.822 0.564,1.002 0.818,1.001 1,1 ')
    }
  })
  tl.add('start')

  // TITLE ANIMATION
  animationTitleAction(titleRef, isDesktop)
  // TEXT ANIMATION
  animationTextAction(textRef, isDesktop)
  // IMAGE ANIMATION
  const image = imageRef.current

  tl.to(image, { y: 0, opacity: 1 }, 'start')
}
