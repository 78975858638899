import de from '@locales/de.json'
import en from '@locales/en.json'
import en_US from '@locales/en_US.json'
import es from '@locales/es.json'
import fr from '@locales/fr.json'
import it from '@locales/it.json'
import nl from '@locales/nl.json'
import pt_BR from '@locales/pt_BR.json'
import pt_PT from '@locales/pt_PT.json'
import ko_KR from '@locales/ko_KR.json'
import zh_CN from '@locales/zh_CN.json'

/**
 * List of all available locales
 *
 * @type {{}}
 */
export const LOCALES = {
  de,
  en,
  en_US,
  es,
  fr,
  it,
  nl,
  pt_BR,
  pt_PT,
  ko_KR,
  zh_CN
}

/**
 * Default locale (backup)
 *
 * @type {string}
 */
export const DEFAULT_CODE = 'en'
