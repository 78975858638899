import styled from '@emotion/styled'

import { mediaMin, sectionGrid, sectionTitle, cem } from '@styles/mixins'

import { ReactComponent as CrossIcon } from '@icons/cross.svg'
import { ReactComponent as ArrowRight } from '@icons/arrow-right.svg'

import { Link } from 'react-router-dom'

export const ListMeetingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: ${cem(17.2)};
  overflow: hidden;

  ${mediaMin.lg} {
    padding-top: ${cem(7)};
    padding-bottom: ${cem(2.3)};
  }
`

export const ListMeetingWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`

export const ListMeetingTitle = styled.h2`
  ${sectionGrid};
  ${sectionTitle({}, { size: 7, lh: 7 })};
`

export const ListMeetingList = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: ${cem(10)};
  ${sectionGrid};

  ${mediaMin.lg} {
    margin-top: ${cem(4.5)};
  }
`

const ListMeetingItemInner = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column-reverse;
  gap: ${cem(3.2)};

  ${mediaMin.lg} {
    gap: ${cem(4)};
    flex-direction: row;
  }
`

export const ListMeetingItemCenter = styled(CrossIcon)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.lacoste};
  color: ${({ theme }) => theme.colors.green};
  width: ${cem(3.2)};
  height: ${cem(3.2)};

  ${mediaMin.lg} {
    width: ${cem(2.2)};
    height: ${cem(2.2)};
  }
`

export const ListMeetingItemArrow = styled(ArrowRight)`
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-100%, -50%);
  height: ${cem(4.3)};
  transition: transform 0.3s ease-out;

  ${mediaMin.lg} {
    height: ${cem(2.5)};
  }
`

export const ListMeetingItemContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const ListMeetingLabel = styled.div`
  width: 75%;
  font-family: ${({ theme }) => theme.fonts.lacoste};
  color: ${({ theme }) => theme.colors.green};
  font-size: ${cem(4.3)};
  line-height: ${cem(4.4)};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaMin.sm} {
    font-size: ${cem(4)};
    line-height: ${cem(4)};
  }

  ${mediaMin.lg} {
    width: 100%;
    font-size: ${cem(3.5)};
    line-height: ${cem(3.5)};
    white-space: nowrap;
  }
`

export const ListMeetingCity = styled.div`
  font-family: ${({ theme }) => theme.fonts.lacoste};
  color: ${({ theme }) => theme.colors.green};
  font-size: ${cem(3.7)};
  line-height: ${cem(4.6)};

  ${mediaMin.sm} {
    font-size: ${cem(3.2)};
    line-height: ${cem(4.1)};
  }

  ${mediaMin.lg} {
    font-size: ${cem(2.5)};
    line-height: ${cem(3.5)};
  }
`

export const ListMeetingItemFlag = styled.img`
  height: ${cem(7.2)};

  ${mediaMin.lg} {
    height: ${cem(6)};
  }
`

export const ListMeetingItemLeft = styled(ListMeetingItemInner)`
  ${ListMeetingItemContent} {
    align-items: center;
    justify-content: center;
  }

  ${mediaMin.lg} {
    flex-direction: row;

    ${ListMeetingLabel} {
      text-align: right;
    }

    ${ListMeetingItemContent} {
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
`

export const ListMeetingItemRight = styled(ListMeetingItemInner)`
  ${ListMeetingItemContent} {
    align-items: center;
    justify-content: center;
  }

  ${mediaMin.lg} {
    flex-direction: row-reverse;

    ${ListMeetingLabel} {
      text-align: left;
    }

    ${ListMeetingItemContent} {
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
`

export const ListMeetingItem = styled(Link)`
  --scale-line: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: ${cem(5.1)} 0;
  position: relative;
  text-decoration: none;
  gap: ${cem(2.5)};

  ${mediaMin.lg} {
    padding: ${cem(4)} 0;
    gap: ${cem(5.5)};
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) scale(var(--scale-line));
    height: ${cem(0.3)};
    width: 100%;
    background-color: ${({ theme }) => theme.colors.green};

    ${mediaMin.md} {
      height: 1px;
    }
  }

  &:before {
    display: none;
  }

  &:first-of-type {
    &:before {
      display: initial;
      content: '';
      top: 0;
      bottom: auto;
    }
  }

  &:hover {
    ${ListMeetingItemArrow} {
      transform: translate(-50%, -50%);
    }
  }
`
