import styled from '@emotion/styled'

export const Container = styled.div`
  width: 100%;
  min-height: calc(var(--rh) - var(--header-height-with-all, 0vh));
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
