import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { mediaMin, sectionText, cem } from '@styles/mixins'

const animationMarquee = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
`

export const Container = styled.div`
  position: relative;
  width: ${cem(10)};
  user-select: none;
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;

  ${mediaMin.sm} {
    width: ${cem(6)};
  }
`

export const MarqueeWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 2;
  transform: translate(-100%, 0) rotate(-90deg);
  transform-origin: center right;
`

export const MarqueeContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  animation: ${animationMarquee} 5s linear infinite reverse;

  ${mediaMin.md} {
    animation: ${animationMarquee} 7.5s linear infinite reverse;
  }
`

export const MarqueeMain = styled.div`
  ${sectionText({ size: 7 }, { size: 4 })};
  white-space: nowrap;
`

export const MarqueeClone = styled(MarqueeMain)`
  position: absolute;
  left: 100%;
  transform: ${({ index }) => `translateX(${index * 100}%)`};
`

export const MarqueeContainer = styled(Container)`
  &.horizontal {
    width: 100%;
    height: ${cem(25.5)};
    background-color: transparent;

    ${MarqueeWrapper} {
      top: 50%;
      left: 0;
      transform: translate(-100%, -50%);
      transform-origin: center center;
    }

    ${MarqueeMain},
    ${MarqueeClone} {
      ${sectionText({ size: 9 }, { size: 9 })};

      a {
        text-decoration: underline;
        color: ${({ theme }) => theme.colors.green};
      }
    }
  }
`
