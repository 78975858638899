import { useRef } from 'react'
import PropTypes from 'prop-types'

import resolveAssetUrl from '@utils/resolveAssetUrl'

import VideoControls from '@components/VideoControls'

import { FullMediaContainer, FullMediaVideo, FullMediaImage, FullMediaLogo } from '@components/FullMedia/style'

import { useAnimator } from '@hooks/useAnimator'
import { animationAction, animationInit } from '@components/FullMedia/animations'

const FullMedia = ({ media }) => {
  const containerRef = useRef(null)
  const mediaRef = useRef(null)

  useAnimator({
    init: () => {
      animationInit({ mediaRef })
    },
    action: () => {
      animationAction({ mediaRef })
    },
    ref: containerRef,
    limit: window.innerHeight / 2
  })

  return (
    <FullMediaContainer ref={containerRef}>
      <FullMediaLogo src={resolveAssetUrl('/img/logo.svg')} alt={'lacoste-90-logo'} />
      {media.type === 'image' ? (
        <FullMediaImage ref={mediaRef} src={resolveAssetUrl(media.src)} alt={media.alt} />
      ) : (
        <>
          <FullMediaVideo
            ref={mediaRef}
            src={resolveAssetUrl(media.src)}
            autoPlay={true}
            muted={true}
            playsInline={true}
            loop={true}
          />
          <VideoControls video={mediaRef} />
        </>
      )}
    </FullMediaContainer>
  )
}

FullMedia.propTypes = {
  media: PropTypes.object.isRequired
}

export default FullMedia
