import { LOCALES, DEFAULT_CODE } from '@locales/utils'

/**
 * Manage locales texts
 *
 * @class Lang
 */
class Lang {
  /**
   * List of all locales
   *
   * @type {{}}
   */
  static locales = LOCALES

  /**
   * Current Locale lang code
   *
   * @type {string}
   */
  static code = ''

  /**
   * Current Locale data
   *
   * @type {{}}
   */
  static locale = {}

  /**
   * Class entrypoint, setup current locale
   *
   * @returns {void}
   */
  static init() {
    let code = window?.Lacoste?.siteprefs?.CURRENT_LOCALE

    if (!Lang.locales[code]) code = window?.Lacoste?.siteprefs?.CURRENT_LANGUAGE

    if (!Lang.locales[code]) code = DEFAULT_CODE

    Lang.code = code
    Lang.locale = Lang.locales[code]
  }

  /**
   * Get string translation from key id
   *
   * @param key
   * @returns {string}
   */
  static t(key) {
    return (
      key
        .replace(/\[([^\]]+)]/g, '.$1')
        .split('.')
        .reduce((v, k) => v[k], Lang.locale) || ''
    )
  }
}

/**
 * Standalone access to translation
 *
 * @param key
 * @returns {string}
 */
export const t = (key) => {
  return Lang.t(key)
}

export default Lang
