import { useRef } from 'react'
import PropTypes from 'prop-types'

import { useAnimator } from '@hooks/useAnimator'

import Button from '@components/Button'

import {
  PushPopupContainer,
  PushPopupWrapper,
  PushPopupTitle,
  PushPopupBlock,
  PushPopupBlockWrapper,
  PushPopupBlockLogo,
  PushPopupBlockImage,
  PushPopupBlockText,
  PushPopupAction,
  PushPopupTitleWrapper
} from '@components/PushPopup/style'

import { animationAction, animationInit } from '@components/PushPopup/animations'
import useDeviceDetect from '@hooks/useDeviceDetect'

const PushPopup = ({ title, text, action = null, color = '#fff', backgroundColor = '#285f41' }) => {
  const { isDesktop } = useDeviceDetect()
  const containerRef = useRef()
  const titleRef = useRef()
  const textRef = useRef()
  const imageRef = useRef()

  useAnimator({
    init: () => {
      animationInit({ titleRef, textRef, imageRef }, isDesktop)
    },
    action: () => {
      animationAction({ titleRef, textRef, imageRef }, isDesktop)
    },
    ref: containerRef,
    limit: window.innerHeight / 3
  })

  return (
    <PushPopupContainer color={color} backgroundColor={backgroundColor} ref={containerRef}>
      <PushPopupWrapper>
        <PushPopupTitleWrapper>
          <PushPopupTitle ref={titleRef}>{title}</PushPopupTitle>
        </PushPopupTitleWrapper>
        <PushPopupBlock>
          <PushPopupBlockWrapper>
            <PushPopupBlockLogo>
              <PushPopupBlockImage ref={imageRef} fill={color} />
            </PushPopupBlockLogo>
            <PushPopupBlockText ref={textRef}>{text}</PushPopupBlockText>
          </PushPopupBlockWrapper>
          {!!action && (
            <PushPopupAction>
              <Button {...action} type={'btn-cta text-black fs--medium ff-normal'}>
                {action.label}
              </Button>
            </PushPopupAction>
          )}
        </PushPopupBlock>
      </PushPopupWrapper>
    </PushPopupContainer>
  )
}

PushPopup.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  action: PropTypes.object,
  color: PropTypes.string,
  backgroundColor: PropTypes.string
}

export default PushPopup
