import styled from '@emotion/styled'
import { mediaMin, cem } from '@styles/mixins'

import { VideoControlsContainer } from '@components/VideoControls/style'

export const HeroMeetingContainer = styled.div`
  width: 100%;
  height: calc(var(--rh) - var(--header-height-with-all, 0vh));
  position: relative;
  z-index: 1;
`

export const HeroMeetingBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: visible;
  height: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  ${VideoControlsContainer} {
    top: calc(var(--rh) - var(--header-height-with-all, 0vh));
    transform: translateY(-100%);
  }

  ${mediaMin.sm} {
    z-index: 1;

    &:before,
    &:after {
      z-index: -2;
      content: '';
      position: absolute;
      width: 50%;
      height: 100%;
      top: 0;
    }

    &:before {
      left: 0;
      background-color: ${({ bgLeft }) => bgLeft};
    }

    &:after {
      left: 50%;
      background-color: ${({ bgRight }) => bgRight};
    }
  }
`

export const HeroMeetingLogo = styled.img`
  display: none;
  position: absolute;
  width: 70%;
  top: 50%;
  left: 50%;
  opacity: 0.1;
  transform: translate(-50%, -50%);
  z-index: -1;

  ${mediaMin.sm} {
    display: initial;
  }
`

export const HeroMeetingVideo = styled.video`
  width: 100%;
  object-fit: cover;
  top: 0;
  height: 100%;

  ${mediaMin.sm} {
    width: 100%;
    height: 100%;
  }
`

export const HeroMeetingMain = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 3;
  pointer-events: none;
`

export const HeroMeetingMainTop = styled.div`
  width: 100%;
  height: 0;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: ${({ background }) => background};
  z-index: 2;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaMin.lg} {
    width: 50%;
  }
`

export const HeroMeetingMainBottom = styled.div`
  width: 100%;
  height: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: ${({ background }) => background};
  z-index: 2;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaMin.lg} {
    width: 50%;
    right: auto;
    left: 50%;
  }
`

export const Title = styled.div`
  width: fit-content;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  --line: 0;
`

export const TitleTop = styled.div`
  font-family: ${({ theme }) => theme.fonts.lacoste};
  color: ${({ color }) => color};
  font-size: ${cem(13.7)};
  line-height: ${cem(13.7)};
  text-align: center;
  white-space: nowrap;
  //transform: translateX(calc(-50% + ${cem(1.5)}));
  overflow: hidden;
  padding: ${cem(1.5)};
  margin: ${cem(-1.5)};
  align-self: flex-start;

  .line {
    transform: translateY(calc(-100% - ${cem(3)}));
  }

  ${mediaMin.sm} {
    font-size: ${cem(8.2)};
    line-height: ${cem(8.2)};
  }
`

export const TitleBottom = styled.div`
  font-family: ${({ theme }) => theme.fonts.lacoste};
  color: ${({ color }) => color};
  font-size: ${cem(13.7)};
  line-height: ${cem(13.7)};
  text-align: center;
  white-space: nowrap;
  //transform: translateX(calc(25% - ${cem(0.75)}));
  overflow: hidden;
  padding: ${cem(1.5)};
  margin: ${cem(-1.5)};
  padding-left: 20vw;
  align-self: flex-start;

  .line {
    transform: translateY(calc(100% + ${cem(3)}));
  }

  ${mediaMin.sm} {
    font-size: ${cem(8.2)};
    line-height: ${cem(8.2)};
    padding-left: 7.5vw;
  }
`

export const SubTitle = styled.div`
  font-family: ${({ theme }) => theme.fonts.lacoste};
  color: ${({ color }) => color};
  font-size: ${cem(6.8)};
  text-align: center;
  white-space: nowrap;
  align-self: flex-start;
  //transform: translateX(calc(-50% + ${cem(1.5)})) translateY(${cem(5.8)});
  transform: translateX(calc(20vw)) translateY(${cem(1.5)});
  overflow: hidden;
  padding: ${cem(1.5)};
  margin: ${cem(-1.5)};
  position: relative;

  &:after {
    content: '';
    width: calc(1% * var(--line) - ${cem(3)});
    height: ${cem(0.4)};
    background-color: ${({ color }) => color};
    position: absolute;
    bottom: ${cem(0.75)};
    left: ${cem(1.5)};
  }

  .line {
    transform: translateY(calc(-100% - ${cem(3)}));
  }

  ${mediaMin.sm} {
    font-size: ${cem(4.1)};
    line-height: ${cem(4.1)};
    transform: translateX(calc(7.5vw)) translateY(${cem(1.5)});
  }
`
