import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { Global, css } from '@emotion/react'

import { gsap } from '@vendor/gsap'
import CustomEase from '@vendor/gsap/CustomEase'
import { SplitText } from '@/vendor/gsap/SplitText'

import useDeviceDetect from '@hooks/useDeviceDetect'

import checkTween from '@utils/checkTween'

import resolveAssetUrl from '@utils/resolveAssetUrl'

import VideoControls from '@components/VideoControls'
import Scroll from '@components/atoms/Scroll'
import Back from '@components/atoms/Back'

import {
  HeroMeetingContainer,
  HeroMeetingBackground,
  HeroMeetingLogo,
  HeroMeetingVideo,
  HeroMeetingMain,
  HeroMeetingMainTop,
  HeroMeetingMainBottom,
  Title,
  TitleTop,
  TitleBottom,
  SubTitle
} from '@components/HeroMeeting/style'

const HeroMeeting = ({ items, video }) => {
  const tweens = { anim: null }
  const containerRef = useRef()
  const videoRef = useRef()
  const { isDesktop } = useDeviceDetect()
  const mainRef = useRef({
    el: null,
    top: null,
    topTitle: { el: null, top: null, bottom: null, city: null },
    bottom: null,
    bottomTitle: { el: null, top: null, bottom: null, city: null }
  })

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0
    })
    // gsap.set(document.body, { height: '100vh', overflow: 'hidden' })
    animationEnter()
  }, [])

  const animationEnter = () => {
    checkTween(tweens, 'anim')
    tweens.anim = gsap.timeline({
      onStart: () => {
        window.scrollTo({
          top: 0,
          left: 0
        })
      },
      onComplete: () => {
        // gsap.set(document.body, { height: 'inherit', overflow: 'visible' })
      }
    })

    if (isDesktop) animationEnterDesktop()
    else animationEnterMobile()
  }

  const animationEnterDesktop = () => {
    const mainEl = mainRef.current
    const videoEl = videoRef.current

    const delay = 0.5

    const texts = []
    mainEl?.topTitle?.top && texts.push(mainEl?.topTitle?.top)
    mainEl?.topTitle?.bottom && texts.push(mainEl?.topTitle?.bottom)
    mainEl?.topTitle?.city && texts.push(mainEl?.topTitle?.city)
    mainEl?.bottomTitle?.top && texts.push(mainEl?.bottomTitle?.top)
    mainEl?.bottomTitle?.bottom && texts.push(mainEl?.bottomTitle?.bottom)
    mainEl?.bottomTitle?.city && texts.push(mainEl?.bottomTitle?.city)

    const split = new SplitText(texts, { type: 'lines', linesClass: 'line' })

    tweens.anim.delay(0.5)
    tweens.anim.add('step0')
    tweens.anim.to(
      mainEl.top,
      {
        top: '0%',
        height: '100%',
        duration: 1,
        ease: CustomEase.create('custom', 'M0,0 C0.094,0.404 0.136,0.632 0.346,0.844 0.522,0.992 0.632,1 1,1 '),
        onComplete: () => {
          gsap.set(mainEl.el, { backgroundColor: 'transparent' })
        }
      },
      `step0+=${delay}`
    )
    tweens.anim.to(
      mainEl.bottom,
      {
        ease: CustomEase.create('custom', 'M0,0 C0.094,0.404 0.136,0.632 0.346,0.844 0.522,0.992 0.632,1 1,1 '),
        height: '100%',
        duration: 1
      },
      `step0+=${delay}`
    )
    tweens.anim.to(
      split.lines,
      {
        y: 0,
        duration: 0.8,
        ease: CustomEase.create('custom', 'M0,0 C0.126,0.382 0.226,0.674 0.384,0.822 0.576,1.002 0.818,1.001 1,1 ')
      },
      `step0+=${delay * 2.5}`
    )
    tweens.anim.to(
      [mainEl?.topTitle?.el, mainEl?.bottomTitle?.el],
      {
        '--line': 100,
        duration: 0.8,
        ease: CustomEase.create('custom', 'M0,0 C0.126,0.382 0.226,0.674 0.384,0.822 0.576,1.002 0.818,1.001 1,1 ')
      },
      `step0+=${delay * 3}`
    )

    tweens.anim.add('step1')
    tweens.anim.to(
      mainEl.top,
      {
        duration: 0.4,
        ease: 'sine.out',
        'clip-path': 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)'
      },
      `step1+=${1.2}`
    )
    tweens.anim.to(
      mainEl.bottom,
      {
        duration: 0.4,
        ease: 'sine.out',
        'clip-path': 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)',
        onStart: () => {
          videoEl.play()
        }
      },
      `step1+=${1.2}`
    )
  }

  const animationEnterMobile = () => {
    const mainEl = mainRef.current
    const videoEl = videoRef.current

    const delay = 0.5

    const texts = []
    mainEl?.topTitle?.top && texts.push(mainEl?.topTitle?.top)
    mainEl?.topTitle?.bottom && texts.push(mainEl?.topTitle?.bottom)
    mainEl?.topTitle?.city && texts.push(mainEl?.topTitle?.city)
    mainEl?.bottomTitle?.top && texts.push(mainEl?.bottomTitle?.top)
    mainEl?.bottomTitle?.bottom && texts.push(mainEl?.bottomTitle?.bottom)
    mainEl?.bottomTitle?.city && texts.push(mainEl?.bottomTitle?.city)

    const split = new SplitText(texts, { type: 'lines', linesClass: 'line' })

    tweens.anim.delay(0.5)
    tweens.anim.add('step0')
    tweens.anim.to(
      mainEl.top,
      {
        top: '0%',
        height: '50%',
        duration: 1,
        ease: CustomEase.create('custom', 'M0,0 C0.094,0.404 0.136,0.632 0.346,0.844 0.522,0.992 0.632,1 1,1 '),
        onComplete: () => {
          gsap.set(mainEl.el, { backgroundColor: 'transparent' })
        }
      },
      `step0+=${delay}`
    )
    tweens.anim.to(
      mainEl.bottom,
      {
        ease: CustomEase.create('custom', 'M0,0 C0.094,0.404 0.136,0.632 0.346,0.844 0.522,0.992 0.632,1 1,1 '),
        height: '50%',
        duration: 1
      },
      `step0+=${delay}`
    )
    tweens.anim.to(
      split.lines,
      {
        y: 0,
        duration: 0.8,
        ease: CustomEase.create('custom', 'M0,0 C0.126,0.382 0.226,0.674 0.384,0.822 0.576,1.002 0.818,1.001 1,1 ')
      },
      `step0+=${delay * 2.5}`
    )
    tweens.anim.to(
      [mainEl?.topTitle?.el, mainEl?.bottomTitle?.el],
      {
        '--line': 100,
        duration: 0.8,
        ease: CustomEase.create('custom', 'M0,0 C0.126,0.382 0.226,0.674 0.384,0.822 0.576,1.002 0.818,1.001 1,1 ')
      },
      `step0+=${delay * 3}`
    )

    tweens.anim.add('step1')
    tweens.anim.to(
      mainEl.top,
      {
        duration: 0.4,
        ease: 'sine.out',
        'clip-path': 'polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%)'
      },
      `step1+=${1.2}`
    )
    tweens.anim.to(
      mainEl.bottom,
      {
        duration: 0.4,
        ease: 'sine.out',
        'clip-path': 'polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%)',
        onStart: () => {
          videoEl.play()
        }
      },
      `step1+=${1.2}`
    )
  }

  return (
    <HeroMeetingContainer ref={containerRef}>
      <Global
        styles={css`
          body {
            --page-color-left: ${items[0].background};
            --page-color-right: ${items[1].background};
          }
        `}
      />
      <HeroMeetingBackground bgLeft={items[0].background} bgRight={items[1].background}>
        <Back />
        <HeroMeetingLogo src={resolveAssetUrl('/img/logo.svg')} alt={'lacoste-90-logo'} />
        <HeroMeetingVideo
          poster={resolveAssetUrl(video?.poster)}
          src={resolveAssetUrl(video.src)}
          ref={videoRef}
          autoPlay={false}
          muted={true}
          playsInline={true}
          loop={true}
        />
        <VideoControls video={videoRef} />
        <Scroll containerRef={containerRef} />
      </HeroMeetingBackground>
      <HeroMeetingMain ref={(ref) => (mainRef.current.el = ref)}>
        <HeroMeetingMainTop background={items[0].background} ref={(ref) => (mainRef.current.top = ref)}>
          <Title ref={(ref) => (mainRef.current.topTitle.el = ref)}>
            {items[0]?.top?.content && (
              <TitleTop ref={(ref) => (mainRef.current.topTitle.top = ref)} color={items[0].top.color}>
                {items[0].top.content}
              </TitleTop>
            )}
            {items[0]?.bottom?.content && (
              <TitleBottom ref={(ref) => (mainRef.current.topTitle.bottom = ref)} color={items[0].bottom.color}>
                {items[0].bottom.content}
              </TitleBottom>
            )}
            {items[0]?.city?.content && (
              <SubTitle ref={(ref) => (mainRef.current.topTitle.city = ref)} color={items[0].city.color}>
                {items[0].city.content}
              </SubTitle>
            )}
          </Title>
        </HeroMeetingMainTop>
        <HeroMeetingMainBottom background={items[1].background} ref={(ref) => (mainRef.current.bottom = ref)}>
          <Title ref={(ref) => (mainRef.current.bottomTitle.el = ref)}>
            {items[1]?.top?.content && (
              <TitleTop ref={(ref) => (mainRef.current.bottomTitle.top = ref)} color={items[1].top.color}>
                {items[1].top.content}
              </TitleTop>
            )}
            {items[1]?.bottom?.content && (
              <TitleBottom ref={(ref) => (mainRef.current.bottomTitle.bottom = ref)} color={items[1].bottom.color}>
                {items[1].bottom.content}
              </TitleBottom>
            )}
            {items[1]?.city?.content && (
              <SubTitle ref={(ref) => (mainRef.current.bottomTitle.city = ref)} color={items[1].city.color}>
                {items[1].city.content}
              </SubTitle>
            )}
          </Title>
        </HeroMeetingMainBottom>
      </HeroMeetingMain>
    </HeroMeetingContainer>
  )
}

HeroMeeting.propTypes = {
  items: PropTypes.array.isRequired,
  video: PropTypes.object.isRequired
}

export default HeroMeeting
