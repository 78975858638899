import styled from '@emotion/styled'
import { colors } from '@styles/theme'
import { cem, mediaMin, sectionButtonContainer, sectionGrid, sectionTitle } from '@styles/mixins'

export const BlockStorySpecialContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.colors.green};
`

export const BlockStorySpecialWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`

export const BlockStorySpecialTitle = styled.h2`
  ${sectionTitle({ color: colors.white }, { color: colors.white, size: 7, lh: 7 })};
  ${sectionGrid};
  padding-top: ${cem(15)};
  padding-bottom: ${cem(12)};

  ${mediaMin.sm} {
    padding-top: ${cem(10)};
    padding-bottom: ${cem(7)};
  }

  ${mediaMin.lg} {
    width: 75%;
  }
`

export const BlockStorySpecialActionWrapper = styled.div`
  ${sectionButtonContainer};
  position: absolute;
  z-index: 1;
  bottom: ${cem(10)};
  left: 50%;
  transform: translateX(-50%);

  ${mediaMin.sm} {
    bottom: ${cem(5.4)};
  }
`
