import styled from '@emotion/styled'
import { colors } from '@styles/theme'
import { mediaMin, sectionGrid, sectionTitle, cem, mediaMax } from '@styles/mixins'

export const HeroHomeContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(var(--rh) - var(--header-height-with-all, 0vh));
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.green};
  overflow: hidden;
`

export const HeroHomeWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: ${cem(2.4)};
`

export const HeroHomeBackgroundLeft = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.lightGreen};
  z-index: 2;
`

export const HeroHomeBackgroundRight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.orange};
  z-index: 2;
`

export const HeroHomeImageLogoTop = styled.img`
  position: absolute;
  top: ${cem(17.1)};
  left: ${cem(9.7)};
  width: ${cem(26.7)};
  height: ${cem(16.1)};
  object-fit: cover;
  z-index: 2;

  ${mediaMin.sm} {
    top: ${cem(7)};
    left: 50%;
    transform: translate(-50%, 0);
    width: ${cem(20.2)};
    height: ${cem(12)};
  }

  ${mediaMin.lg} {
    top: ${cem(3.5)};
    width: ${cem(10.2)};
    height: ${cem(6)};
  }
`

export const HeroHomeImageLogoCenter = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${cem(40)};
  height: ${cem(24)};
  object-fit: cover;
  z-index: 3;

  ${mediaMin.sm} {
    width: ${cem(20.5)};
    height: ${cem(12.4)};
  }
`

export const HeroHomeContent = styled.div`
  ${sectionGrid};
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 3;

  ${mediaMin.sm} {
    width: 80%;
  }

  ${mediaMin.lg} {
    width: 50%;
    height: 100%;
    align-items: flex-start;
    padding: 0 ${cem(12)};
  }
`

const HeroHomeTitle = styled.h2`
  ${sectionTitle({ color: colors.white, size: 10 })}
  text-align: left;
  transition: all ease-out 0.3s;

  .line {
    display: inline-flex;
    opacity: 0;
    will-change: transform;
  }

  ${mediaMin.sm} {
    font-size: ${cem(7)};
    line-height: ${cem(8.4)};
    text-align: left;
  }

  ${mediaMin.lg} {
    font-size: ${cem(6)};
    line-height: ${cem(7.4)};
    text-align: left;
  }
`

export const HeroHomeTitleTop = styled(HeroHomeTitle)``

export const HeroHomeTitleBottom = styled(HeroHomeTitle)``

export const HeroHomeVisual = styled.div`
  width: 50%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  overflow: hidden;

  ${mediaMax.lg} {
    display: none;
  }
`

export const HeroHomeBackground = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: #acd0b7;
  position: absolute;
  top: 0;
  left: 0;
`

export const HeroHomeImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 20%;
`
