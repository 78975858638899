import { useRef } from 'react'
import PropTypes from 'prop-types'

import { useAnimator } from '@hooks/useAnimator'

import resolveAssetUrl from '@utils/resolveAssetUrl'

import {
  BlockEditoContainer,
  BlockEditoWrapper,
  BlockEditoTitle,
  BlockEditoImage,
  BlockEditoText,
  BlockEditoList,
  BlockEditoItem,
  BlockEditoItemContainer,
  BlockEditoVisual,
  BlockEditoBackground,
  BlockEditoItemImage,
  BlockEditoItemTitle,
  BlockEditoItemCity,
  BlockEditoItemCityArrow
} from '@components/BlockEdito/style'

import { animationAction, animationInit } from '@components/BlockEdito/animations'
import useDeviceDetect from '@hooks/useDeviceDetect'
import GTM from '@utils/GTM'

const BlockEdito = ({ title, image, text, list }) => {
  const { isDesktop } = useDeviceDetect()
  const containerRef = useRef()
  const titleRef = useRef()
  const textRef = useRef()
  const visualRef = useRef()
  const listRef = useRef()

  useAnimator({
    init: () => {
      animationInit({ titleRef, visualRef, textRef, listRef }, isDesktop)
    },
    action: () => {
      animationAction({ titleRef, visualRef, textRef, listRef }, isDesktop)
    },
    ref: containerRef,
    limit: window.innerHeight / 2
  })

  const handleItemClick = (gtm) => {
    if (gtm) {
      GTM.push({ ecommerce: null })
      GTM.push(GTM.getSelectPromotionData(gtm))
    }
  }

  return (
    <BlockEditoContainer ref={containerRef}>
      <BlockEditoWrapper className={`${!image && 'hasNoImage'} ${!(text && text.length) && 'hasNoText'}`}>
        <BlockEditoTitle ref={titleRef} dangerouslySetInnerHTML={{ __html: title }} />
        {text && text.length && (
          <BlockEditoText ref={textRef} dangerouslySetInnerHTML={{ __html: text }}></BlockEditoText>
        )}
        {image && (
          <BlockEditoVisual ref={visualRef}>
            <BlockEditoImage className={'image'} src={resolveAssetUrl(image.src)} alt={image.alt} />
            <BlockEditoBackground className={'background'} />
          </BlockEditoVisual>
        )}
        {list && list.length && (
          <BlockEditoList ref={listRef}>
            {list.map((item, index) => (
              <BlockEditoItem
                key={`edito-list-item-${index}`}
                to={item?.action?.href}
                onClick={() => handleItemClick(item?.action?.gtm)}
                className={'item'}
              >
                <BlockEditoItemContainer>
                  <BlockEditoItemImage src={resolveAssetUrl(item?.image?.src)} alt={item?.image?.alt} />
                  <BlockEditoItemTitle>{item?.title}</BlockEditoItemTitle>
                </BlockEditoItemContainer>
                <BlockEditoItemCity className={'city'} bgColor={item?.city?.background} textColor={item?.city?.color}>
                  {item?.city?.name}
                </BlockEditoItemCity>
                <BlockEditoItemCityArrow />
              </BlockEditoItem>
            ))}
          </BlockEditoList>
        )}
      </BlockEditoWrapper>
    </BlockEditoContainer>
  )
}

BlockEdito.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
  text: PropTypes.string,
  list: PropTypes.array
}

export default BlockEdito
