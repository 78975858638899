import { useRef } from 'react'
import PropTypes from 'prop-types'

import { useAnimator } from '@hooks/useAnimator'

import resolveAssetUrl from '@utils/resolveAssetUrl'

import Button from '@components/Button'

import {
  PushProductContainer,
  PushProductWrapper,
  PushProductTitle,
  PushProductContent,
  PushProductActionWrapper,
  PushProductProductBackground,
  PushProductProductImage,
  PushProductProduct,
  PushProductProductName,
  PushProductProductDesc
} from '@components/PushProduct/style'

import { animationAction, animationInit } from '@components/PushProduct/animations'
import useDeviceDetect from '@hooks/useDeviceDetect'

const PushProduct = ({
  title = '',
  image = { src: '', alt: '' },
  images = '',
  product = '',
  description = '',
  action = null
}) => {
  const { isDesktop } = useDeviceDetect()
  const containerRef = useRef()
  const titleRef = useRef()

  useAnimator({
    init: () => {
      animationInit({ titleRef }, isDesktop)
    },
    action: () => {
      animationAction({ titleRef }, isDesktop)
    },
    ref: containerRef,
    limit: window.innerHeight / 2
  })

  return (
    <PushProductContainer ref={containerRef}>
      <PushProductWrapper>
        <PushProductTitle ref={titleRef} dangerouslySetInnerHTML={{ __html: title }} />
        <PushProductContent>
          {!!images.length &&
            images.map((i, k) => <PushProductProductBackground key={k} src={resolveAssetUrl(i.src)} alt={i.alt} />)}
          <PushProductProductImage src={resolveAssetUrl(image.src)} alt={image.alt} />
        </PushProductContent>
        <PushProductProduct>
          <PushProductProductName>{product}</PushProductProductName>
          <PushProductProductDesc>{description}</PushProductProductDesc>
        </PushProductProduct>
        {!!action && (
          <PushProductActionWrapper>
            <Button {...action} type={'btn-cta text-black btn--large fs--medium ff-normal'}>
              {action.label}
            </Button>
          </PushProductActionWrapper>
        )}
      </PushProductWrapper>
    </PushProductContainer>
  )
}

PushProduct.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  images: PropTypes.arrayOf(PropTypes.object),
  product: PropTypes.string.isRequired,
  description: PropTypes.string,
  action: PropTypes.object
}

export default PushProduct
