import { gsap } from '@vendor/gsap'
import CustomEase from '@vendor/gsap/CustomEase'

import { animationTitleAction, animationTitleInit } from '@animations/title'

export const animationInit = ({ listRef, titleRef, marqueeRef }, isDesktop = false) => {
  // TITLE ANIMATION
  animationTitleInit(titleRef, isDesktop)

  // LIST ANIMATION
  const list = listRef.current
  const items = list?.querySelectorAll('.item')

  if (items.length) {
    for (let i = 0; i < items.length; i++) {
      const el = items[i]
      const item = {
        el,
        left: {
          el: el?.querySelector('.left'),
          label: el?.querySelector('.left .label'),
          city: el?.querySelector('.left .city'),
          flag: el?.querySelector('.left .flag')
        },
        right: {
          el: el?.querySelector('.right'),
          label: el?.querySelector('.right .label'),
          city: el?.querySelector('.right .city'),
          flag: el?.querySelector('.right .flag')
        },
        center: el?.querySelector('.center')
      }

      // flags
      gsap.set([item.left.flag, item.right.flag], { y: '400%', opacity: 0 })
      // center
      gsap.set(item.center, { opacity: 0 })
      if (isDesktop) {
        // labels
        gsap.set(item.left.label, { x: '-35vw', opacity: 0 })
        gsap.set(item.right.label, { x: '35vw', opacity: 0 })
        // cities
        gsap.set(item.left.city, { x: '-18vw', opacity: 0 })
        gsap.set(item.right.city, { x: '18vw', opacity: 0 })
      } else {
        // labels
        gsap.set(item.left.label, { x: '-50vw', opacity: 0 })
        gsap.set(item.right.label, { x: '50vw', opacity: 0 })
        // cities
        gsap.set(item.left.city, { x: '-50vw', opacity: 0 })
        gsap.set(item.right.city, { x: '50vw', opacity: 0 })
      }
    }
  }

  // MARQUEE ANIMATION
  const marquee = marqueeRef.current
  gsap.set(marquee.containerRef.current, { y: '100%', opacity: 0 })
}

export const animationAction = ({ listRef, titleRef, marqueeRef }, isDesktop = false) => {
  // TITLE ANIMATION
  animationTitleAction(titleRef, isDesktop)

  // LIST ANIMATION
  const list = listRef.current
  const items = list?.querySelectorAll('.item')

  const long = isDesktop ? 0.7 : 0.9
  const short = isDesktop ? 0.5 : 0.7

  if (items.length) {
    for (let i = 0; i < items.length; i++) {
      const el = items[i]
      const item = {
        el,
        left: {
          el: el?.querySelector('.left'),
          label: el?.querySelector('.left .label'),
          city: el?.querySelector('.left .city'),
          flag: el?.querySelector('.left .flag')
        },
        right: {
          el: el?.querySelector('.right'),
          label: el?.querySelector('.right .label'),
          city: el?.querySelector('.right .city'),
          flag: el?.querySelector('.right .flag')
        },
        center: el?.querySelector('.center')
      }

      const tl = gsap.timeline({
        delay: i * 0.1
      })

      tl.add('start')
      // flags
      tl.to([item.left.flag, item.right.flag], { y: 0, duration: long, ease: CustomEase.get('title') }, 'start')
      // lines
      tl.to(item.el, { '--scale-line': '1', duration: long, ease: CustomEase.get('title') }, 'start')
      // center
      tl.to(item.center, { opacity: 1, duration: long, ease: CustomEase.get('title') }, 'start')
      tl.to(
        [item.left.flag, item.right.flag],
        { opacity: 1, duration: short, ease: CustomEase.create('listLabelOpacity', 'M0,0 C0.77,0 0.962,0.802 1,1 ') },
        'start'
      )
      // labels
      tl.to([item.left.label, item.right.label], { x: 0, duration: long, ease: CustomEase.get('title') }, 'start')
      tl.to(
        [item.left.label, item.right.label],
        { opacity: 1, duration: short, ease: CustomEase.get('listLabelOpacity') },
        'start'
      )
      // cities
      tl.to([item.left.city, item.right.city], { x: 0, duration: long, ease: CustomEase.get('title') }, 'start+=.2')
      tl.to(
        [item.left.city, item.right.city],
        { opacity: 1, duration: short, ease: CustomEase.get('listLabelOpacity') },
        'start+=.2'
      )
    }
  }

  // MARQUEE ANIMATION
  const marquee = marqueeRef.current
  gsap.to(marquee.containerRef.current, {
    y: 0,
    opacity: 1,
    duration: long,
    ease: CustomEase.create('basic', 'M0,0 C0.142,0.43 0.116,0.564 0.372,0.822 0.564,1.002 0.818,1.001 1,1 ')
  })
}
