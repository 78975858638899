import styled from '@emotion/styled'
import { mediaMin, cem } from '@styles/mixins'

import { StoryNavContainer, StoryNavItem } from '@components/Story/atoms/Nav/style'

export const StorySpecialContainer = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  user-select: none;

  ${mediaMin.sm} {
    height: calc(var(--rh) - var(--header-height-with-all, 0vh));
  }
`

export const StorySpecialHeader = styled.div`
  width: 100%;
  padding: ${cem(7.8)} ${cem(6.3)} 0;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;

  ${mediaMin.sm} {
    padding: ${cem(5.48)} ${cem(4.6)} 0;
    left: 0;
    transform: none;

    ${StoryNavContainer} {
      gap: ${cem(0.5)};
      justify-content: flex-start;
    }

    ${StoryNavItem} {
      width: ${cem(8.3)};
      height: ${cem(0.3)};
    }
  }
`

export const StorySpecialWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
`

export const StorySpecialItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
`

export const StorySpecialItemLeft = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StorySpecialItemRight = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StorySpecialItemImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
