import styled from '@emotion/styled'
import { Swiper } from 'swiper/react'

import { colors } from '@styles/theme'

import { mediaMin, sectionGrid, sectionSubGrid, sectionText, sectionTitle, cem } from '@styles/mixins'

import 'swiper/css'
import 'swiper/css/virtual'

export const BlockSliderContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.colors.green};
  padding-top: ${cem(17.2)};
  padding-bottom: ${cem(12)};

  ${mediaMin.sm} {
    padding-top: ${cem(9.5)};
    padding-bottom: ${cem(9.5)};
  }
`

export const BlockSliderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  ${mediaMin.sm} {
    flex-direction: row;
    gap: ${cem(0)};
  }

  ${mediaMin.lg} {
    flex-direction: row;
    gap: ${cem(28)};
  }
`

export const BlockSliderContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: ${cem(8.4)};

  ${mediaMin.sm} {
    padding-right: ${cem(10)};
    width: 50%;
    gap: ${cem(5)};
  }

  .line {
    white-space: nowrap;
  }
`

export const BlockSliderTitle = styled.h3`
  ${sectionTitle({ color: colors.white }, { size: 8, lh: 7.5 })};
  ${sectionGrid};
  padding-bottom: ${cem(10.4)};

  ${mediaMin.sm} {
    padding-right: 0;
    padding-bottom: ${cem(4.4)};
  }

  ${mediaMin.lg} {
    padding-bottom: ${cem(0)};
    padding-right: 0;
  }
`

export const BlockSliderText = styled.div`
  ${sectionText({ color: colors.white }, { size: 2.2, lh: 3.5 })};
  ${sectionGrid};
  ${sectionSubGrid};

  ${mediaMin.sm} {
    padding-right: 0;
  }
`

export const BlockSliderSwiper = styled(Swiper)`
  width: 100%;
  padding-left: ${cem(3)};
  overflow: visible !important;

  ${mediaMin.sm} {
    width: 50%;
    padding-left: 0;
    overflow: hidden !important;
  }

  .swiper-slide {
    width: 84%;
    height: 50vh;
    margin-right: ${cem(4)};

    ${mediaMin.lg} {
      width: 60%;
      height: 70vh;
      margin-right: ${cem(2)};
    }
  }
`

export const BlockSliderItem = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BlockSliderImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
