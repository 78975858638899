import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import resolveAssetUrl from '@utils/resolveAssetUrl'

import { t } from '@locales'

import { BackContainer, BackText, BackIcon } from '@components/atoms/Back/style'

const Back = ({ title = t('BACK') }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(-1)
  }

  return (
    <BackContainer onClick={handleClick}>
      <BackIcon src={resolveAssetUrl('/img/icons/arrow-back.svg')} alt={title} />
      <BackText>{title}</BackText>
    </BackContainer>
  )
}

Back.propTypes = {
  title: PropTypes.string
}

export default Back
