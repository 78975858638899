import { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { gsap } from '@/vendor/gsap'
import { SplitText } from '@/vendor/gsap/SplitText'

import checkTween from '@/utils/checkTween'

import Scroll from '@components/atoms/Scroll'
import Back from '@components/atoms/Back'

import {
  HeroCommunityContainer,
  HeroCommunityWrapper,
  HeroCommunityBlock,
  HeroCommunityText,
  HeroCommunityTitle,
  HeroCommunityBlockBottom
} from './style.js'
import { css, Global } from '@emotion/react'
import theme from '@styles/theme'

const HeroCommunity = ({
  titleCategory,
  titleName,
  titleCity,
  backgroundColorTop,
  backgroundColorBottom,
  textColorTop,
  textColorBottom
}) => {
  const section = useRef(null)
  const wrapperRef = useRef(null)
  const items = useRef({})
  const tweens = useRef({ anim: null })

  const animationEnter = (blockBottom, titleTop, titleBottom, textBottom) => {
    checkTween(tweens, 'anim')

    tweens.anim = gsap.timeline({
      defaults: { duration: 1.2, ease: 'power4.inOut' },
      onStart: () => {
        window.scrollTo({
          top: 0,
          left: 0
        })
      },
      onComplete: () => {
        // gsap.set(document.body, { height: 'inherit', overflow: 'visible' })
      }
    })

    tweens.anim.add('start')
    tweens.anim.fromTo(wrapperRef.current, { y: '100%' }, { y: 0 }, 'start')
    tweens.anim.fromTo(blockBottom, { height: '0' }, { height: '50%' }, 'start+=.75')
    tweens.anim.from(titleTop, { y: '100%', duration: 1.4, ease: 'expo' }, 'start+=1.75')
    tweens.anim.from(titleBottom, { y: '-100%', duration: 1.4, ease: 'expo' }, '<')
    tweens.anim.from(textBottom, { y: '100%', duration: 1.4, ease: 'expo' }, '<+0.3')
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0
    })
    // gsap.set(document.body, { height: '100vh', overflow: 'hidden' })
    /* --------- Animate Intro */
    const { titleTop, titleBottom, textBottom, blockBottom } = items.current

    const texts = [titleTop, titleBottom, textBottom]
    texts.forEach((text) => new SplitText(text, { type: 'lines', linesClass: 'line' }))

    const lineTop = titleTop.querySelector('.line')
    const lineBottom = titleBottom.querySelector('.line')
    const lineText = textBottom.querySelector('.line')

    animationEnter(blockBottom, lineTop, lineBottom, lineText)
  }, [])

  return (
    <HeroCommunityContainer
      textColorTop={textColorTop}
      textColorBottom={textColorBottom}
      bgColorTop={backgroundColorTop}
      bgColorBottom={backgroundColorBottom}
      ref={section}
    >
      <Global
        styles={css`
          body {
            --page-color-left: ${backgroundColorTop};
            --page-color-right: ${backgroundColorBottom};
          }
        `}
      />
      <HeroCommunityWrapper ref={wrapperRef}>
        <HeroCommunityBlock>
          <HeroCommunityTitle ref={(ref) => (items.current.titleTop = ref)} data-split>
            {titleCategory}
          </HeroCommunityTitle>
          <HeroCommunityTitle ref={(ref) => (items.current.titleBottom = ref)} data-split>
            {titleName}
          </HeroCommunityTitle>
          <HeroCommunityText
            className={`${titleName.length === 0 && 'noSubTitle'}`}
            ref={(ref) => (items.current.textBottom = ref)}
            data-split
          >
            {titleCity}
          </HeroCommunityText>
        </HeroCommunityBlock>
        <HeroCommunityBlockBottom ref={(ref) => (items.current.blockBottom = ref)} />
        <Scroll containerRef={section} color={titleName.length === 0 ? theme.colors.green : theme.colors.white} />
        <Back />
      </HeroCommunityWrapper>
    </HeroCommunityContainer>
  )
}

HeroCommunity.propTypes = {
  titleCategory: PropTypes.string,
  titleName: PropTypes.string,
  titleCity: PropTypes.string,
  backgroundColorTop: PropTypes.string,
  backgroundColorBottom: PropTypes.string,
  textColorTop: PropTypes.string,
  textColorBottom: PropTypes.string
}

export default HeroCommunity
