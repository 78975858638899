export const colors = {
  black: '#191919',
  dark: '#292929',
  dark2: '#292929',
  grey: '#e4e4e4',
  white: '#FFFFFF',
  green: '#285f41',
  lightGreen: '#a2d2b5',
  orange: '#e0812f'
}

export const fonts = {
  lacoste: '"Lacoste", "Arial", "Helvetica", sans-serif',
  archivo: '"Archivo", "Arial", "Helvetica", sans-serif'
}

const theme = {
  colors,
  fonts
}

export default theme
