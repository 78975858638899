// LACOSTE
import LacosteMedium from '@fonts/Lacoste/Lacoste-Medium.otf'

// ARCHIVO
import ArchivoRegular from '@fonts/Archivo/Archivo-Regular.ttf'
import ArchivoBold from '@fonts/Archivo/Archivo-Bold.ttf'

export default {
  LacosteMedium,
  ArchivoRegular,
  ArchivoBold
}
