import styled from '@emotion/styled'
import { mediaMin, sectionTitle, cem } from '@styles/mixins'

export const HeroCommunityContainer = styled.div`
  --text-color-top: ${({ textColorTop }) => textColorTop};
  --text-color-bottom: ${({ textColorBottom }) => textColorBottom};
  --bg-color-top: ${({ bgColorTop }) => bgColorTop};
  --bg-color-bottom: ${({ bgColorBottom }) => bgColorBottom};

  width: 100%;
  height: calc(var(--rh) - var(--header-height-with-all, 0vh));
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  overflow: hidden;
`

export const HeroCommunityWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 ${cem(1)};
  background-color: var(--bg-color-top);
`

export const HeroCommunityBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  transform: translate(0, ${cem(6)});
  z-index: 1;

  ${mediaMin.sm} {
    transform: translate(0, ${cem(3)});
  }
`

export const HeroCommunityTitle = styled.h2`
  ${sectionTitle({ size: 16, lh: 18.2 }, { size: 10, lh: 11.8 })};
  min-height: ${cem(18.2)};
  color: var(--text-color-top);

  &:nth-of-type(2) {
    color: var(--text-color-bottom);
    padding-left: ${cem(10)};
  }

  ${mediaMin.sm} {
    color: var(--text-color-top);
    min-height: ${cem(11.8)};
  }
`

export const HeroCommunityText = styled.p`
  ${sectionTitle({ size: 7, lh: 8.8 })}
  color: var(--text-color-bottom) !important;
  text-decoration: underline;
  padding-left: ${cem(10)};
  margin-top: ${cem(3)};

  &.noSubTitle {
    padding-left: 0;
    text-align: center;
    transform: translateY(${cem(-18.2)});

    ${mediaMin.sm} {
      transform: translateY(${cem(-10.8)});
    }
  }

  ${mediaMin.sm} {
    ${sectionTitle({ size: 4.5, lh: 5.8 })}
    margin-top: 0;
  }
`

export const HeroCommunityBlockBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  background-color: var(--bg-color-bottom);
`
