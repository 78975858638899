import styled from '@emotion/styled'
import { mediaMin, cem } from '@styles/mixins'

import { Link } from 'react-router-dom'

export const ButtonLabel =
  process.env.NODE_ENV !== 'development'
    ? styled.span``
    : styled.span`
        font-family: ${({ theme }) => theme.fonts.archivo};
        color: ${({ theme }) => theme.colors.black};
        font-size: ${cem(4.874)};
        text-align: center;
        white-space: nowrap;

        ${mediaMin.sm} {
          font-size: ${cem(2.458)};
        }
      `

export const ButtonWrapperInternal =
  process.env.NODE_ENV !== 'development'
    ? styled(Link)``
    : styled(Link)`
        padding: ${cem(6.6)} ${cem(14.2)};
        background-color: ${({ theme }) => theme.colors.white};
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: ${cem(10)};
        border: ${cem(0.2)} solid ${({ theme }) => theme.colors.grey};
        text-decoration: none;

        &.dark {
          background-color: ${({ theme }) => theme.colors.dark};
          ${ButtonLabel} {
            color: ${({ theme }) => theme.colors.white};
          }
        }

        ${mediaMin.sm} {
          padding: ${cem(2.1)} ${cem(4.2)};
        }
      `

export const ButtonWrapperExternal = ButtonWrapperInternal.withComponent('a')
