import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'

import useDeviceDetect from '@hooks/useDeviceDetect'

import { gsap } from '@vendor/gsap'
import { SplitText } from '@/vendor/gsap/SplitText'

import checkTween from '@utils/checkTween'
import resolveAssetUrl from '@utils/resolveAssetUrl'

import Scroll from '@components/atoms/Scroll'
import CustomEase from '@vendor/gsap/CustomEase'

import {
  HeroHomeContainer,
  HeroHomeWrapper,
  HeroHomeBackgroundLeft,
  HeroHomeBackgroundRight,
  HeroHomeImageLogoTop,
  HeroHomeImageLogoCenter,
  HeroHomeVisual,
  HeroHomeImage,
  HeroHomeContent,
  HeroHomeTitleTop,
  HeroHomeBackground,
  HeroHomeTitleBottom
} from '@components/HeroHome/style'

import { css, Global } from '@emotion/react'
import theme from '@styles/theme'

/**
 * HeroHome Component
 *
 * @param titleTop {string}
 * @param titleBottom {string}
 * @param logo {object}
 * @param image {object}
 *
 * @returns {JSX.Element}
 * @constructor
 */
const HeroHome = ({ logo, image, titleTop = '', titleBottom = '' }) => {
  const { isDesktop } = useDeviceDetect()
  const items = useRef({})
  const containerRef = useRef()
  const tweens = useRef({ anim: null })

  useEffect(() => {
    /* ------------ Animation */
    const { bgLeft, bgRight, imgLogoTop, imgLogoCenter, titleTop, titleBottom } = items.current

    const texts = [titleTop, titleBottom]
    new SplitText(texts, { type: 'lines,words', linesClass: 'line', wordsClass: 'word' })

    const lineTitleTop = titleTop.querySelectorAll('.line')
    const lineTitleBottom = titleBottom.querySelectorAll('.line')

    checkTween(tweens, 'anim')

    tweens.anim = gsap.timeline({
      defaults: {
        duration: 1,
        ease: CustomEase.create('basic', 'M0,0 C0.142,0.43 0.116,0.564 0.372,0.822 0.564,1.002 0.818,1.001 1,1 ')
      },
      onStart: () => {
        window.scrollTo({
          top: 0,
          left: 0
        })
      },
      onComplete: () => {
        gsap.set([imgLogoCenter, bgLeft, bgRight], { display: 'none' })
        // gsap.set(document.body, { height: 'inherit', overflow: 'visible' })

        if (!isDesktop) {
          setTimeout(() => {
            const bounds = containerRef.current.getBoundingClientRect()
            if (window.scrollY < bounds.height) {
              window.scrollTo({
                top: bounds.height,
                left: 0,
                behavior: 'smooth'
              })
            }
          }, 2000)
        }
      }
    })

    window.scrollTo({
      top: 0,
      left: 0
    })
    // gsap.set(document.body, { height: '100vh', overflow: 'hidden' })
    tweens.anim.delay(0.5)

    tweens.anim.add('start')
    tweens.anim.fromTo(bgLeft, { height: '0' }, { height: '100%', duration: 0.6 }, 'start')
    tweens.anim.fromTo(bgRight, { height: '0' }, { height: '100%', duration: 0.6 }, 'start')
    tweens.anim.fromTo(imgLogoCenter, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.6 }, 'start')
    tweens.anim.fromTo(imgLogoCenter, { scale: 20, y: '-200%' }, { scale: 1, y: 0, duration: 0.6 }, 'start')

    tweens.anim.add('leave-bars-v')
    tweens.anim.fromTo(bgLeft, { bottom: '0' }, { bottom: '100%', duration: 0.6 }, 'leave-bars-v')
    tweens.anim.fromTo(bgRight, { top: '0' }, { top: '100%', duration: 0.6 }, 'leave-bars-v')

    tweens.anim.add('enter-bars-h')
    tweens.anim.set(
      bgRight,
      {
        top: 0,
        left: '100%',
        right: 'auto',
        height: '100%',
        width: '100%',
        x: 0,
        y: 0,
        zIndex: 999
      },
      'enter-bars-h'
    )
    tweens.anim.to(
      bgRight,
      {
        left: 0,
        duration: 0.6
      },
      'enter-bars-h'
    )

    tweens.anim.add('leave-bars-h')
    tweens.anim.set(imgLogoCenter, { display: 'none' }, 'leave-bars-h')
    tweens.anim.set(
      bgLeft,
      {
        top: 0,
        left: 0,
        right: 'auto',
        height: '100%',
        width: '100%',
        x: 0,
        y: 0,
        zIndex: 999
      },
      'leave-bars-h'
    )
    tweens.anim.to(
      bgRight,
      {
        left: '-100%',
        duration: 0.6
      },
      'leave-bars-h'
    )
    tweens.anim.to(
      bgLeft,
      {
        left: '-100%',
        duration: 0.6
      },
      'leave-bars-h+=.1'
    )

    tweens.anim.from(imgLogoTop, { x: '10vh', autoAlpha: 0, duration: 1.2 }, '-=0.5')
    tweens.anim.to([lineTitleTop, lineTitleBottom], { autoAlpha: 1, duration: 1.6 }, '<-0.5')

    tweens.anim.from(
      [lineTitleTop, lineTitleBottom],
      {
        x: '85%',
        stagger: 0.2,
        duration: 1.2,
        ease: CustomEase.create('title', 'M0,0,C0.088,0.22,0.078,1.012,1,1')
      },
      '<'
    )

    if (isDesktop) {
      const { visual } = items.current

      const background = visual.querySelector('.background')
      const image = visual.querySelector('.image')
      gsap.set(background, { x: '100%' })
      gsap.set(image, { x: '100%', opacity: 0 })

      let ease = CustomEase.create(
        'custom',
        'M0,0 C0.29,0 0.32,0.096 0.34,0.124 0.386,0.19 0.452,0.362 0.484,0.502 0.504,0.592 0.545,0.698 0.6,0.8 0.628,0.834 0.704,1 1,1 '
      )

      // 4.75 - 2.8
      const imageDelay = 1.95

      tweens.anim.to(
        background,
        {
          duration: 1,
          delay: 0.5,
          x: 0,
          ease
        },
        `-=${imageDelay}`
      )

      tweens.anim.to(
        background,
        {
          duration: 1,
          x: '-100%',
          ease
        },
        `-=${imageDelay - 1.5}`
      )

      tweens.anim.to(
        image,
        {
          duration: 1.25,
          opacity: 1,
          ease
        },
        `-=${imageDelay - (0.55 + 0.1875)}`
      )
      tweens.anim.to(
        image,
        {
          duration: 1,
          x: '0',
          ease
        },
        `-=${imageDelay - (0.55 + 0.1875)}`
      )

      // tweens.anim.to([visual], { width: '50%' }, '-=4.75')
    }
  }, [])

  return (
    <HeroHomeContainer ref={containerRef}>
      <Global
        styles={css`
          body {
            --page-color-left: ${theme.colors.lightGreen};
            --page-color-right: ${theme.colors.green};
          }
        `}
      />
      <HeroHomeWrapper>
        <HeroHomeBackgroundLeft ref={(ref) => (items.current.bgLeft = ref)} />
        <HeroHomeBackgroundRight ref={(ref) => (items.current.bgRight = ref)} />
        <HeroHomeImageLogoTop
          src={resolveAssetUrl(logo.src)}
          alt={logo.alt}
          ref={(ref) => (items.current.imgLogoTop = ref)}
        />
        <HeroHomeImageLogoCenter
          src={resolveAssetUrl(logo.src)}
          alt={logo.alt}
          ref={(ref) => (items.current.imgLogoCenter = ref)}
        />
        <HeroHomeContent ref={(ref) => (items.current.content = ref)}>
          <HeroHomeTitleTop ref={(ref) => (items.current.titleTop = ref)}>{titleTop}</HeroHomeTitleTop>
          {titleBottom.length > 0 && (
            <HeroHomeTitleBottom ref={(ref) => (items.current.titleBottom = ref)}>{titleBottom}</HeroHomeTitleBottom>
          )}
        </HeroHomeContent>
        {isDesktop && (
          <HeroHomeVisual ref={(ref) => (items.current.visual = ref)}>
            <HeroHomeBackground className={'background'} />
            <HeroHomeImage
              className={'image'}
              ref={(ref) => (items.current.image = ref)}
              src={resolveAssetUrl(image.src, false)}
              alt={image.alt}
            />
          </HeroHomeVisual>
        )}
        <Scroll containerRef={containerRef} />
      </HeroHomeWrapper>
    </HeroHomeContainer>
  )
}

HeroHome.propTypes = {
  logo: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  titleTop: PropTypes.string.isRequired,
  titleBottom: PropTypes.string
}

export default HeroHome
