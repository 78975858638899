import { gsap } from '@vendor/gsap'

import { animationTitleAction, animationTitleInit } from '@animations/title'
import { animationTextAction, animationTextInit } from '@animations/text'
import CustomEase from '@vendor/gsap/CustomEase'

export const animationInit = ({ titleRef, textRef, swiperRef }, isDesktop) => {
  // TITLE ANIMATION
  animationTitleInit(titleRef, isDesktop)
  // TEXT ANIMATION
  textRef.current && animationTextInit(textRef)
  // SWIPER ANIMATION
  const swiper = swiperRef.current
  const items = swiper?.querySelectorAll('.item')
  const imgs = swiper?.querySelectorAll('.image')

  gsap.set(items, { x: '100%', opacity: 0 })
  gsap.set(imgs, { scale: 1.5 })
}

export const animationAction = ({ titleRef, textRef, swiperRef }, isDesktop) => {
  const tl = gsap.timeline({
    defaults: {
      duration: 1.2,
      ease: CustomEase.create('basic', 'M0,0 C0.142,0.43 0.116,0.564 0.372,0.822 0.564,1.002 0.818,1.001 1,1 ')
    }
  })
  tl.add('start')

  // TITLE ANIMATION
  animationTitleAction(titleRef, isDesktop)
  // TEXT ANIMATION
  textRef.current && animationTextAction(textRef)
  // SWIPER ANIMATION
  const swiper = swiperRef.current
  const items = swiper?.querySelectorAll('.item')
  const imgs = swiper?.querySelectorAll('.image')

  tl.to(items, { x: 0, opacity: 1, stagger: 0.2 }, 'start')
  tl.to(imgs, { scale: 1, stagger: 0.2 }, 'start')
}
