import PropTypes from 'prop-types'
import resolveAssetUrl from '@utils/resolveAssetUrl'
import { useEffect, useRef, useState } from 'react'

import useDeviceDetect from '@hooks/useDeviceDetect'

import { gsap } from '@vendor/gsap'
import ScrollTrigger from '@vendor/gsap/ScrollTrigger'

import checkTween from '@utils/checkTween'

import { useAnimator } from '@hooks/useAnimator'
import { animationAction, animationInit } from '@components/PopupStore/animations'

import { colors } from '@styles/theme'

import {
  PopupStoreContainer,
  PopupStoreWrapper,
  PopupStoreHead,
  PopupStoreHeadImage,
  PopupStoreHeadTitle,
  PopupStoreList,
  PopupStoreItem,
  PopupStoreItemLabel,
  PopupStoreItemTitle,
  PopupStoreBlock,
  PopupStoreBlockBackground,
  PopupStoreBlockMedias,
  PopupStoreBlockMedia,
  PopupStoreBlockPreview
} from '@components/PopupStore/style'

import GTM from '@utils/GTM'

gsap.registerPlugin(ScrollTrigger)

/**
 * PopupStore Component
 *
 * @param media {object}
 * @param title {string}
 * @param stores {array}
 * @returns {JSX.Element}
 * @constructor
 */
const PopupStore = ({ title = '8 Popups', stores = [] }) => {
  const { isDesktop } = useDeviceDetect()
  const [images, setImages] = useState([])
  const [preview, setPreview] = useState([])
  const [active, setActive] = useState(null)
  const tweens = useRef({ cover: null, media: null })
  const containerRef = useRef(null)
  const logoRef = useRef(null)
  const titleRef = useRef(null)
  const listRef = useRef(null)
  const coverRef = useRef(null)
  const mediasRef = useRef(null)

  useAnimator({
    init: () => {
      animationInit({ titleRef, logoRef, listRef, containerRef }, isDesktop)
    },
    action: () => {
      animationAction({ titleRef, logoRef, listRef, containerRef }, isDesktop)
    },
    ref: containerRef,
    limit: window.innerHeight / 2.5
  })

  const handleMouseMove = ({ currentTarget, clientX, clientY }) => {
    if (!isDesktop) return
    const cover = coverRef.current

    const bound = currentTarget.getBoundingClientRect()
    const bound2 = cover.getBoundingClientRect()
    const xVal = clientX - (bound.left + bound2.width / 4)
    const yVal = clientY - bound.top

    gsap.to(coverRef.current, {
      x: xVal,
      y: yVal,
      duration: 1,
      ease: 'none'
    })
  }

  const handleMouseEnter = (index) => {
    if (!isDesktop) return
    const container = containerRef.current
    const cover = coverRef.current
    const item = container.querySelectorAll('.item')[index]

    if (!cover?.classList.contains('active')) {
      cover?.classList.add('active')
      checkTween(tweens, 'cover')

      tweens.cover = gsap.timeline()
      tweens.cover.to(cover, { clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' })
    }

    if (!item.classList.contains('active')) {
      if (container?.querySelector('.active')) container?.querySelector('.active').classList.remove('active')
      item.classList.add('active')

      setPreview((curr) => [...curr, { index, key: curr.length }])
      setActive(index)
    }
  }

  const handleMouseLeave = () => {
    const container = containerRef.current
    const cover = coverRef.current

    if (container?.querySelector('.active')) container?.querySelector('.active').classList.remove('active')

    cover?.classList.remove('active')
    checkTween(tweens, 'cover')

    tweens.cover = gsap.timeline({
      onComplete: () => {
        setPreview([])
        setActive(null)
      }
    })
    tweens.cover.to(cover, { clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)' })
  }

  const handleScroll = () => {
    const container = containerRef.current
    const list = listRef.current
    const cover = coverRef.current
    const items = list?.querySelectorAll('.item')

    let active = null
    for (let i = 0; i < items.length; i++) {
      const item = items[i]

      const bound = item.getBoundingClientRect()
      const data = {
        el: item,
        index: i,
        center: bound.y + bound.height / 2,
        distance: window.innerHeight - (bound.y + bound.height / 2)
      }
      const top = window.innerHeight / 2 - bound.height
      const bottom = window.innerHeight / 2 + bound.height

      if (data.center >= top && data.center <= bottom) {
        if (active) {
          if (active.distance > data.distance) active = data
        } else {
          active = data
        }
      }
    }

    if (active) {
      if (!cover?.classList.contains('active')) {
        cover?.classList.add('active')
        checkTween(tweens, 'cover')

        tweens.cover = gsap.timeline()
        tweens.cover.to(cover, { clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' })
      }
      if (!active.el.classList.contains('active')) {
        if (container?.querySelector('.active')) container?.querySelector('.active').classList.remove('active')
        active.el.classList.add('active')

        setPreview((curr) => [...curr, { index: active.index, key: curr.length }])
        setActive(active.index)
      }
    } else {
      if (container?.querySelector('.active')) container?.querySelector('.active').classList.remove('active')
      if (cover?.classList.contains('active')) {
        cover?.classList.remove('active')
        checkTween(tweens, 'cover')

        tweens.cover = gsap.timeline({
          onComplete: () => {
            setPreview([])
            setActive(null)
          }
        })
        tweens.cover.to(cover, { clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)' })
      }
    }
  }

  useEffect(() => {
    const device = isDesktop ? 'desktop' : 'mobile'
    const list = []
    stores.forEach((s) => {
      const media = s.media
      const src = media.src.replace(/%device%/, device)
      const image = {
        src: resolveAssetUrl(src, false),
        alt: media.alt
      }

      list.push(image)
    })

    setImages(list)

    if (!isDesktop) {
      const cover = coverRef.current

      gsap.to(cover, {
        scrollTrigger: {
          trigger: containerRef.current,
          markers: false,
          start: 'top top',
          end: 'bottom+=100 top',
          pin: cover
        },
        bottom: '-100%'
      })

      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (preview.length) {
      const cover = coverRef.current
      const newPreview = preview[preview.length - 1]
      const el = cover?.querySelector(`.preview-${newPreview?.index}-${newPreview?.key}`)

      gsap.to(el, { clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)' })
    }
  }, [preview])

  const getColorOrFallBack = (index, key, fallback) => {
    return index > 0 && stores[index] && stores[index][key] ? stores[index][key] : fallback
  }

  const handleItemClick = (gtm) => {
    if (gtm) {
      GTM.push({ ecommerce: null })
      GTM.push(GTM.getSelectPromotionData(gtm))
    }
  }

  return (
    <PopupStoreContainer
      textColor={getColorOrFallBack(active, 'textColor', colors.white)}
      backgroundColor={getColorOrFallBack(active, 'backgroundColor', colors.green)}
      ref={containerRef}
    >
      <PopupStoreWrapper>
        <PopupStoreHead>
          <PopupStoreHeadImage ref={logoRef} fill={getColorOrFallBack(active, 'textColor', '#FFFFFF')} />
          <PopupStoreHeadTitle ref={titleRef}>{title}</PopupStoreHeadTitle>
        </PopupStoreHead>
        <PopupStoreList ref={listRef} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
          {stores.map((store, index) => (
            <PopupStoreItem
              className={'item'}
              key={index}
              data-bg-color={store.backgroundColor}
              onMouseEnter={() => handleMouseEnter(index)}
            >
              <PopupStoreItemLabel>{store.month}</PopupStoreItemLabel>
              <PopupStoreItemTitle to={store?.action?.href} onClick={() => handleItemClick(store?.action?.gtm)}>
                {store.city}
              </PopupStoreItemTitle>
            </PopupStoreItem>
          ))}
        </PopupStoreList>
        <PopupStoreBlock ref={coverRef}>
          <PopupStoreBlockBackground />
          <PopupStoreBlockMedias ref={mediasRef}>
            {images.map((image, index) => (
              <PopupStoreBlockMedia className={'media'} key={index} src={image.src} alt={image.alt} />
            ))}

            {preview.map((p) => (
              <PopupStoreBlockPreview
                key={`preview-${p.key}`}
                className={`preview-${p.index}-${p.key}`}
                src={images[p.index]?.src}
                alt={images[p.index]?.alt}
              />
            ))}
          </PopupStoreBlockMedias>
        </PopupStoreBlock>
      </PopupStoreWrapper>
    </PopupStoreContainer>
  )
}

PopupStore.propTypes = {
  title: PropTypes.string.isRequired,
  stores: PropTypes.array.isRequired
}

export default PopupStore
