import styled from '@emotion/styled'
import { colors } from '@styles/theme'

import { mediaMin, sectionGrid, sectionTitle, sectionText, cem } from '@styles/mixins'

import { ReactComponent as StoreLogo } from '@icons/store.svg'

import { Link } from 'react-router-dom'

export const PopupStoreWrapper = styled.div`
  ${sectionGrid};
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 3;

  ${mediaMin.md} {
    overflow: initial;
  }
`

export const PopupStoreHead = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: var(--title-bar, 0);
    height: 1px;
    background-color: var(--txt-color);
    transition: background-color ease-out 0.5s;
  }
`

export const PopupStoreHeadTitle = styled.div`
  ${sectionTitle({ size: 13, lh: 12.2, color: 'var(--txt-color)' }, { size: 8, color: 'var(--txt-color)' })};
  margin-bottom: ${cem(8.2)};
  transition: color ease-out 0.5s;
  transform-origin: left center;

  ${mediaMin.md} {
    margin-bottom: ${cem(2)};
  }
`

export const PopupStoreHeadImage = styled(StoreLogo)`
  width: ${cem(15)};
  height: ${cem(15)};
  object-fit: contain;
  margin-bottom: ${cem(5.7)};

  ${mediaMin.lg} {
    width: ${cem(10)};
    height: ${cem(10)};
  }
`

export const PopupStoreList = styled.ul`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${cem(12)};
  z-index: 4;

  ${mediaMin.md} {
    margin-top: ${cem(7)};
  }
`

export const PopupStoreItemLabel = styled.small`
  ${sectionText({ size: 5, lh: 6.5, color: 'var(--txt-color)' }, { size: 2.5, lh: 2.5, color: 'var(--txt-color)' })};
  transition: color ease-out 0.5s;
`

export const PopupStoreItemTitle = styled(Link)`
  ${sectionTitle({ size: 16, lh: 18.5, color: 'var(--txt-color)' }, { size: 7.5, lh: 9.5, color: 'var(--txt-color)' })};
  white-space: nowrap;
  position: relative;
  text-decoration: none;
  width: fit-content;
  transition: color ease-out 0.5s;

  &:hover {
    color: var(--txt-color);
  }

  &:after {
    content: '';
    position: absolute;
    top: ${cem(18)};
    left: 0;
    width: 0;
    height: ${cem(0.6)};
    background-color: var(--txt-color);
    transition: width 1.2s ease-out, background-color ease-out 0.5s;

    ${mediaMin.md} {
      top: ${cem(8.5)};
    }
  }
`

export const PopupStoreItem = styled.li`
  display: inline-flex;
  flex-direction: column;
  width: fit-content;
  padding: ${cem(5)} 0;
  pointer-events: all;
  cursor: pointer;

  &.active {
    ${PopupStoreItemTitle} {
      &:after {
        width: 100%;
      }
    }
  }

  ${mediaMin.md} {
    padding: ${cem(2.5)} 0;
  }
`

export const PopupStoreBlock = styled.div`
  position: absolute;
  //top: ${cem(50)};
  bottom: calc(100% - ${cem(126.4)});
  right: ${cem(10)};
  width: ${cem(54.3)};
  height: ${cem(76.4)};
  overflow: hidden;
  clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);

  ${mediaMin.md} {
    top: ${cem(10)};
    right: 0;
    width: ${cem(120)};
    height: ${cem(78)};
  }
`

export const PopupStoreBlockBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.lightGreen};
`

export const PopupStoreBlockMedias = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const PopupStoreBlockMedia = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
`

export const PopupStoreBlockPreview = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
`

export const PopupStoreContainer = styled.div`
  --title-bar: 0%;
  --txt-color: ${({ textColor }) => textColor};
  --bg-color: ${({ backgroundColor }) => backgroundColor};

  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: ${cem(10)};
  padding-bottom: ${cem(13.6)};
  background-color: var(--bg-color);
  pointer-events: none;
  overflow: hidden;
  transition: background-color ease-out 0.5s;

  ${mediaMin.md} {
    overflow: initial;
  }
`
