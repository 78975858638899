import gsap from '@/vendor/gsap'
import { SplitText } from '@/vendor/gsap/SplitText'
import CustomEase from '@vendor/gsap/CustomEase'

export const animationTitleInit = (ref, isDesktop = false) => {
  const el = ref.current

  ref.split = new SplitText(el, { type: 'lines', linesClass: 'line' })

  gsap.set(ref.split.lines, { x: isDesktop ? '25%' : '50%', opacity: 0 })

  return ref
}

export const animationTitleAction = (ref, isDesktop = false) => {
  const { split } = ref

  const tl = gsap.timeline()

  tl.add('title')

  const duration = isDesktop ? 1.2 : 1.5
  const delay = 0.2

  split.lines.forEach((l, i) => {
    tl.to(
      l,
      {
        x: 0,
        opacity: 1,
        duration: duration - delay * i,
        delay: delay * i,
        ease: CustomEase.create('title', 'M0,0,C0.088,0.22,0.078,1.012,1,1')
      },
      'title'
    )
  })

  return tl
}
