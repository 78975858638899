import { gsap } from '@vendor/gsap'
import SplitText from '@vendor/gsap/SplitText'
import CustomEase from '@vendor/gsap/CustomEase'

export const animationInit = ({ titleRef, mediaRef, videoRef, flagsRef }) => {
  // FLAGS ANIMATION
  const flags = flagsRef?.current
  if (flags) {
    const items = flags.querySelectorAll('.flag')

    gsap.set(items, { y: '300%', opacity: 0 })
  }

  // TITLE ANIMATION
  const title = titleRef.current

  titleRef.split = new SplitText(title, { type: 'lines', linesClass: 'line' })

  titleRef.split.lines.forEach((l, i) => {
    gsap.set(l, { y: `${(i + 1) * 10}vh`, opacity: 0 })
  })

  // VIDEO ANIMATION
  const media = mediaRef.current
  const video = videoRef.current
  const background = media.querySelector('.background')

  gsap.set(background, { x: '100%' })
  gsap.set(video, { x: '100%', opacity: 0 })
}

export const animationAction = ({ titleRef, mediaRef, videoRef, flagsRef }, isDesktop) => {
  const tl = gsap.timeline({
    defaults: {
      duration: 1.2,
      ease: CustomEase.create('basic', 'M0,0 C0.142,0.43 0.116,0.564 0.372,0.822 0.564,1.002 0.818,1.001 1,1 ')
    }
  })

  tl.add('start')

  // FLAGS ANIMATION
  const flags = flagsRef?.current
  if (flags) {
    const items = flags.querySelectorAll('.flag')

    tl.to(items, { y: 0, stagger: 0.05, opacity: 1 }, 'start')
  }

  // TITLE ANIMATION
  const { split } = titleRef

  tl.to(
    split.lines,
    {
      y: 0,
      opacity: 1,
      stagger: 0.1,
      duration: 1.2,
      ease: CustomEase.create('basic', 'M0,0 C0.142,0.43 0.116,0.564 0.372,0.822 0.564,1.002 0.818,1.001 1,1 ')
    },
    'start'
  )

  // VIDEO ANIMATION
  const media = mediaRef.current
  const video = videoRef.current
  const background = media.querySelector('.background')

  let ease = CustomEase.create(
    'custom',
    'M0,0 C0.29,0 0.32,0.096 0.34,0.124 0.386,0.19 0.452,0.362 0.484,0.502 0.504,0.592 0.545,0.698 0.6,0.8 0.628,0.834 0.704,1 1,1 '
  )

  tl.to(
    background,
    {
      duration: isDesktop ? 1 : 0.75 / 2,
      delay: 0.5,
      x: 0,
      ease
    },
    'start'
  )

  tl.to(
    background,
    {
      duration: isDesktop ? 1 : 0.75,
      x: '-100%',
      ease
    },
    `start+=${(isDesktop ? 1 : 0.75 / 2) + 0.5}`
  )

  tl.to(
    video,
    {
      duration: isDesktop ? 1.25 : 0.75,
      opacity: 1,
      ease
    },
    `start+=${(isDesktop ? 1 : 0.75 / 2) + 0.5}`
  )
  tl.to(
    video,
    {
      duration: isDesktop ? 1 : 0.75,
      x: '0',
      ease
    },
    `start+=${(isDesktop ? 1 : 0.75 / 2) + 0.5}`
  )
}
