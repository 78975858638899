import { useRef } from 'react'
import PropTypes from 'prop-types'

import { useAnimator } from '@hooks/useAnimator'

import resolveAssetUrl from '@utils/resolveAssetUrl'

import VideoControls from '@components/VideoControls'

import {
  BlockVideoContainer,
  BlockVideoWrapper,
  BlockVideoTitle,
  BlockVideoMedia,
  BlockVideoMediaBackground,
  BlockVideoPlayer,
  BlockVideoFlags,
  BlockVideoFlag
} from '@components/BlockVideo/style'

import { animationAction, animationInit } from '@components/BlockVideo/animations'
import useDeviceDetect from '@hooks/useDeviceDetect'

/**
 * Block Video Component
 *
 * @param title {string}
 * @param video {object}
 * @param flags {array}
 * @returns {*}
 * @constructor
 */
const BlockVideo = ({ title, video, flags = [] }) => {
  const { isDesktop } = useDeviceDetect()
  const containerRef = useRef()
  const titleRef = useRef()
  const flagsRef = useRef(null)
  const videoRef = useRef(null)
  const mediaRef = useRef(null)

  useAnimator({
    init: () => {
      animationInit({ titleRef, mediaRef, videoRef, flagsRef }, isDesktop)
    },
    action: () => {
      videoRef.current.play()
      animationAction({ titleRef, mediaRef, videoRef, flagsRef }, isDesktop)
    },
    ref: containerRef,
    limit: window.innerHeight / 2
  })

  return (
    <BlockVideoContainer ref={containerRef}>
      <BlockVideoWrapper>
        {flags && flags.length && (
          <BlockVideoFlags ref={flagsRef}>
            {flags.map((flag, k) => (
              <BlockVideoFlag className={'flag'} key={k} src={resolveAssetUrl(flag.src)} alt={flag.alt} />
            ))}
          </BlockVideoFlags>
        )}
        <BlockVideoTitle
          ref={titleRef}
          className={`${flags.length > 0 && 'flags'}`}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <BlockVideoMedia ref={mediaRef}>
          <BlockVideoMediaBackground className={'background'} />
          <BlockVideoPlayer
            poster={resolveAssetUrl(video?.poster)}
            src={resolveAssetUrl(video.src)}
            autoPlay={false}
            muted={true}
            playsInline={true}
            loop={true}
            ref={videoRef}
          />
          <VideoControls video={videoRef} />
        </BlockVideoMedia>
      </BlockVideoWrapper>
    </BlockVideoContainer>
  )
}

BlockVideo.propTypes = {
  flags: PropTypes.array,
  title: PropTypes.string.isRequired,
  video: PropTypes.object.isRequired,
  action: PropTypes.object
}

export default BlockVideo
