import Controller from '@utils/Controller'

import useDeviceDetect from '@hooks/useDeviceDetect'

/**
 * Resolve asset url (path relative to env)
 *
 * @param path {string}
 * @returns {string}
 */
const resolveAssetUrl = (path, deep = true) => {
  let formattedPath = path

  if (deep) {
    const { isDesktop } = useDeviceDetect()

    const device = isDesktop ? 'desktop' : 'mobile'
    formattedPath = path.replace(/%device%/, device)
  }

  if (process.env.NODE_ENV === 'development') return formattedPath

  return `${Controller.get('url')}${formattedPath}`
}

export default resolveAssetUrl
