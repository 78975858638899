import React from 'react'
import { createRoot } from 'react-dom/client'
import { ThemeProvider, Global } from '@emotion/react'
import { RecoilRoot } from 'recoil'
import { RouterProvider } from 'react-router-dom'

import { gsap } from '@vendor/gsap'
import CustomEase from '@vendor/gsap/CustomEase'
import { SplitText } from '@/vendor/gsap/SplitText'
import Observer from '@vendor/gsap/Observer'

gsap.registerPlugin(CustomEase)
gsap.registerPlugin(SplitText)
gsap.registerPlugin(Observer)

import lacosteData from '@data/lacoste.json'

import Controller from '@utils/Controller'
import Lang from '@locales'

Controller.init('lacoste90-controller')
Lang.init()

import theme from '@styles/theme'
import globalStyles from '@styles/global-styles'

import router from '@router'

if (process.env.NODE_ENV === 'development') {
  window.Lacoste = lacosteData
}

createRoot(document.getElementById('lacoste90-root')).render(
  <RecoilRoot>
    <ThemeProvider theme={theme}>
      <Global styles={globalStyles} />
      <RouterProvider basename="/v2" router={router} />
    </ThemeProvider>
  </RecoilRoot>
)
