import styled from '@emotion/styled'

import { cem } from '@styles/mixins'

export const StoryNavContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${cem(1.5)};

  --progress: ${({ progress }) => `${progress}%`};
`

export const StoryNavItem = styled.div`
  width: 100%;
  height: ${cem(0.8)};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme }) => `${theme.colors.white}50`};
`

export const StoryNavItemInner = styled.div`
  width: 0;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  &.prev {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
  }

  &.curr {
    width: 0;
  }
`
