import { useRef } from 'react'

import PropTypes from 'prop-types'

import { useAnimator } from '@hooks/useAnimator'

import resolveAssetUrl from '@utils/resolveAssetUrl'

import { SwiperSlide } from 'swiper/react'

import {
  BlockSliderContainer,
  BlockSliderWrapper,
  BlockSliderContent,
  BlockSliderTitle,
  BlockSliderText,
  BlockSliderSwiper,
  BlockSliderItem,
  BlockSliderImage
} from '@components/BlockSlider/style'

import { animationAction, animationInit } from '@components/BlockSlider/animations'
import useDeviceDetect from '@hooks/useDeviceDetect'

const BlockSlider = ({ title, text, images = [] }) => {
  const { isDesktop } = useDeviceDetect()
  const containerRef = useRef()
  const titleRef = useRef()
  const textRef = useRef()
  const swiperRef = useRef()

  useAnimator({
    init: () => {
      animationInit({ titleRef, textRef, swiperRef }, isDesktop)
    },
    action: () => {
      animationAction({ titleRef, textRef, swiperRef }, isDesktop)
    },
    ref: containerRef,
    limit: window.innerHeight / 2
  })

  return (
    <BlockSliderContainer ref={containerRef}>
      <BlockSliderWrapper>
        <BlockSliderContent>
          <BlockSliderTitle ref={titleRef}>{title}</BlockSliderTitle>
          {text && text.length && <BlockSliderText ref={textRef}>{text}</BlockSliderText>}
        </BlockSliderContent>
        <BlockSliderSwiper slidesPerView="auto" ref={swiperRef}>
          {images.map((v, k) => (
            <SwiperSlide key={`swiper-${k}`}>
              <BlockSliderItem className={'item'}>
                <BlockSliderImage className={'image'} src={resolveAssetUrl(v.src)} alt={v.alt} />
              </BlockSliderItem>
            </SwiperSlide>
          ))}
        </BlockSliderSwiper>
      </BlockSliderWrapper>
    </BlockSliderContainer>
  )
}

BlockSlider.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  images: PropTypes.array
}

export default BlockSlider
