/**
 * Provide access to server-side variables
 *
 * @class Controller
 */
class Controller {
  /**
   * DOM Element controller
   *
   * @type {HTMLElement | {prototype: HTMLElement, new(): HTMLElement}}
   */
  static el = HTMLElement

  /**
   * Setup DOM Element controller
   *
   * @param id {string}
   * @returns {void}
   */
  static init(id) {
    this.el = document.getElementById(id)
  }

  /**
   * Get Attribute from DOM Element controller
   *
   * @param key {string}
   * @returns {string}
   */
  static get(key) {
    return this?.el?.dataset[key] || ''
  }
}

export default Controller
