import { useRef } from 'react'
import PropTypes from 'prop-types'

import { useAnimator } from '@hooks/useAnimator'

import StorySpecial from '@components/Story/Special'
import Button from '@components/Button'

import { useState } from 'react'

import {
  BlockStorySpecialContainer,
  BlockStorySpecialWrapper,
  BlockStorySpecialTitle,
  BlockStorySpecialActionWrapper
} from '@components/BlockStorySpecial/style'

import { animationAction, animationInit } from '@components/BlockStorySpecial/animations'
import useDeviceDetect from '@hooks/useDeviceDetect'
import Controller from '@utils/Controller'
import { t } from '@locales'

const BlockStorySpecial = ({ title, items }) => {
  const { isDesktop } = useDeviceDetect()
  const [index, setIndex] = useState(0)
  const [paused, setPaused] = useState(true)
  const medias = items.map((i) => i.medias)

  const containerRef = useRef()
  const titleRef = useRef()

  useAnimator({
    init: () => {
      animationInit({ titleRef }, isDesktop)
    },
    action: () => {
      animationAction({ titleRef }, isDesktop)
      setPaused(false)
    },
    ref: containerRef,
    limit: window.innerHeight / 2
  })

  const handleChange = (i) => {
    setIndex(i)
  }

  return (
    <BlockStorySpecialContainer ref={containerRef}>
      <BlockStorySpecialWrapper>
        <BlockStorySpecialTitle ref={titleRef} dangerouslySetInnerHTML={{ __html: title }} />
        <StorySpecial paused={paused} medias={medias} onChange={handleChange} />
        {!!items[index]?.action && (
          <BlockStorySpecialActionWrapper>
            <Button {...items[index].action} type={'btn-cta text-black btn--large fs--medium ff-normal'}>
              {items[index].action.label}
            </Button>
            <Button
              title={t('SELECTION')}
              target={'_self'}
              gtm={items[index].cid.gtm}
              href={`${Controller.get('plpUrl')}?cgid=${items[index].cid.id}`}
              type={'btn-cta text-black btn--large fs--medium ff-normal'}
            >
              {t('SELECTION')}
            </Button>
          </BlockStorySpecialActionWrapper>
        )}
      </BlockStorySpecialWrapper>
    </BlockStorySpecialContainer>
  )
}

BlockStorySpecial.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  action: PropTypes.object
}

export default BlockStorySpecial
