import PropTypes from 'prop-types'

import resolveAssetUrl from '@utils/resolveAssetUrl'

import { t } from '@locales'

import { ScrollContainer, ScrollText, ScrollIcon } from '@components/atoms/Scroll/style'
import theme from '@styles/theme'

const Scroll = ({ title = t('SCROLL'), containerRef, color = theme.colors.white }) => {
  const handleClick = () => {
    const container = containerRef.current
    const { bottom } = container.getBoundingClientRect()

    window.scrollTo({
      top: window.scrollY + bottom,
      left: 0,
      behavior: 'smooth'
    })
  }

  return (
    <ScrollContainer color={color} onClick={handleClick}>
      <ScrollText>{title}</ScrollText>
      <ScrollIcon>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 50">
          <path d="M64 50L0 3.2 2.4 0 64 45l61.7-45 2.3 3.2z" fill={color} />
        </svg>
      </ScrollIcon>
    </ScrollContainer>
  )
}

Scroll.propTypes = {
  title: PropTypes.string,
  containerRef: PropTypes.object,
  color: PropTypes.string
}

export default Scroll
