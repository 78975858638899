import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  VideoControlsContainer,
  VideoControlsWrapper,
  VideoControlsSound,
  VideoControlsPlay
} from '@components/VideoControls/style'

const VideoControls = ({ video }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [isMute, setIsMute] = useState(false)

  useEffect(() => {
    const el = video?.current

    el?.addEventListener('play', handlePlay)
    el?.addEventListener('pause', handlePause)

    setIsMute(el?.muted)
    setIsPlaying(!el?.paused)

    return () => {
      el?.removeEventListener('play', handlePlay)
      el?.removeEventListener('pause', handlePause)
    }
  }, [])

  const handlePlay = () => {
    setIsPlaying(true)
  }
  const handlePause = () => {
    setIsPlaying(false)
  }

  const triggerMuteChange = () => {
    const el = video?.current

    el.muted = !el?.muted

    setIsMute(el?.muted)
  }

  const triggerPlayChange = () => {
    const el = video?.current

    if (isPlaying) el?.pause()
    else el?.play()
  }

  return (
    <VideoControlsContainer>
      <VideoControlsWrapper>
        <VideoControlsSound
          onClick={triggerMuteChange}
          className={`${!isMute && 'active'}`}
          title={'Sound Button'}
          aria-label={'Sound Button'}
        >
          <svg
            viewBox="0 0 40 40"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <path
              fill="currentColor"
              d="M26.9 1c-1-.6-2.3-.5-3.3.1l-12.1 9.6H5.6c-2.4 0-4.5 1.8-4.5 4v10.6c0 2.3 2 4 4.5 4h6l11.9 9.4.1.1c.5.4 1.2.5 1.7.5s1.2-.1 1.7-.4c1-.5 1.5-1.5 1.5-2.5v-33c-.2-.9-.7-1.9-1.6-2.4zm-1.1 35.3c0 .1-.1.3-.3.3-.1.1-.4.1-.6 0l-12.1-9.7s-.1 0-.1-.1c-.1 0-.1-.1-.3-.1H5.6c-1 0-1.9-.6-1.9-1.5V14.5c0-.8.9-1.5 1.9-1.5h6.8c.1 0 .1-.1.3-.1 0 0 .1 0 .1-.1L25 3c.1.1.4.1.6.3.1 0 .3.1.3.3v32.7h-.1zm6.2-8.9c-.1 0-.3 0-.5-.1-.6-.3-1-1-.8-1.6.8-2 1.2-4 1.2-6.2 0-2.6-.6-5.3-1.8-7.6-.3-.6 0-1.4.6-1.6.6-.3 1.4 0 1.7.6 1.3 2.8 2 5.7 2 8.7 0 2.4-.4 4.8-1.3 7-.1.6-.6.8-1.1.8zm7-7.9c0 4.4-1.3 8.7-3.6 12.5-.3.4-.6.6-1 .6-.3 0-.5 0-.6-.3-.6-.4-.8-1.1-.4-1.8 2.2-3.3 3.2-7.2 3.2-11.1A20.77 20.77 0 0 0 33.8 9c-.4-.6-.1-1.4.5-1.8s1.4-.1 1.8.5c1.8 3.6 2.9 7.7 2.9 11.8z"
            />
            <path d="M36.5 36.4a1.19 1.19 0 0 1-.1 1.8c-.3.3-.5.4-.9.4s-.6-.1-.9-.4L1.8 3.4c-.5-.5-.5-1.3.2-1.8a1.19 1.19 0 0 1 1.8.1l32.7 34.7zM24.9 3.3a1.1 1.1 0 0 1 .8 0c.1 0 .3.1.3.3v18.9l2.6 2.8V3.5c0-1-.5-2-1.5-2.5-1-.6-2.3-.5-3.3.1 0 0-.1 0-.1.1L13.2 9.3l1.7 1.9 10-7.9zm.9 33c0 .1-.1.3-.3.3-.1.1-.4.1-.6 0l-12.1-9.7s-.3-.3-.4-.3H5.6c-1 0-1.9-.6-1.9-1.5V14.5c0-.8.9-1.5 1.9-1.5h3.2l-2.4-2.5h-1c-2.4 0-4.5 1.8-4.5 4v10.6c0 2.3 2 4 4.5 4h6l11.9 9.4s.1 0 .1.1c.5.4 1.2.5 1.7.5s1.2-.1 1.7-.4c1-.5 1.5-1.5 1.5-2.5V34l-2.6-2.8c.1 0 .1 5.1.1 5.1zM32 19.5c0 2.1-.4 4.3-1.2 6.2-.3.6.1 1.4.8 1.6.1 0 .3.1.5.1a1.3 1.3 0 0 0 1.2-.8c.9-2.3 1.3-4.7 1.3-7 0-3-.6-5.9-2-8.7-.3-.6-1-.9-1.7-.6-.6.3-1 1-.6 1.6 1.1 2.5 1.7 5 1.7 7.6zm4.5 0c0 3.9-1.2 7.8-3.2 11.1-.4.6-.3 1.4.4 1.8.3.1.4.3.6.3.4 0 .9-.3 1-.6 2.4-3.7 3.6-8.1 3.6-12.5 0-4.2-1.2-8.2-3.2-11.7-.4-.6-1.2-.8-1.8-.5-.6.4-.8 1.1-.5 1.8 2.1 3 3.1 6.7 3.1 10.3z" />
          </svg>
        </VideoControlsSound>
        <VideoControlsPlay
          onClick={triggerPlayChange}
          className={`${isPlaying && 'active'}`}
          title={'Play pause button'}
          aria-label={'Play pause button'}
        >
          <svg
            viewBox="0 0 40 40"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <path fill="none" stroke="currentColor" strokeWidth="2.6667" d="M6.667 2.667v34.672l26.002-17.337z" />
            <path strokeWidth="2.6667" d="M15.333 2.667v34.667M24.667 2.667v34.667" />
          </svg>
        </VideoControlsPlay>
      </VideoControlsWrapper>
    </VideoControlsContainer>
  )
}

VideoControls.propTypes = {
  video: PropTypes.object
}

export default VideoControls
