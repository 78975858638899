import styled from '@emotion/styled'
import { colors } from '@styles/theme'

import { cem, mediaMin, sectionTitle } from '@styles/mixins'

export const ScrollText = styled.span`
  ${sectionTitle({ size: 3, lh: 0, color: 'var(--scroll-color)' }, { size: 1.4, lh: 1, color: 'var(--scroll-color)' })};
  font-family: ${({ theme }) => theme.fonts.archivo};
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: ${cem(4.5)};
  letter-spacing: 0.1em;

  ${mediaMin.sm} {
    font-size: ${cem(2)};
    line-height: ${cem(0)};
  }

  ${mediaMin.lg} {
    font-size: ${cem(1.4)};
    line-height: ${cem(1)};
  }

  ${mediaMin.lg} {
    margin-bottom: ${cem(2.8)};
  }
`

export const ScrollIcon = styled.div`
  width: ${cem(13)};
  height: ${cem(5)};
  object-fit: contain;
  transition: transform 0.3s ease-out;

  ${mediaMin.lg} {
    width: ${cem(6.5)};
    height: ${cem(2.5)};
  }
`

export const ScrollContainer = styled.div`
  --scroll-color: ${({ color }) => color};

  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  bottom: ${cem(8.3)};
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 1;

  ${mediaMin.lg} {
    bottom: ${cem(4)};
    cursor: pointer;
  }

  &:hover {
    ${ScrollIcon} {
      transform: translateY(25%);
    }
  }
`
