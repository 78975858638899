import { forwardRef, useImperativeHandle, useRef } from 'react'
import PropTypes from 'prop-types'

import { StoryNavContainer, StoryNavItem, StoryNavItemInner } from '@components/Story/atoms/Nav/style'

const StoryNav = forwardRef(({ index = 0, len = 0 }, ref) => {
  const containerRef = useRef(null)
  const itemsRef = useRef({})

  // PUBLIC METHODS
  useImperativeHandle(ref, () => ({
    containerRef,
    itemsRef
  }))

  return (
    <StoryNavContainer ref={containerRef}>
      {[...Array(len)].map((i, k) => (
        <StoryNavItem key={k}>
          <StoryNavItemInner
            ref={(r) => (itemsRef.current[k] = r)}
            className={`${k < index ? 'prev' : k === index ? 'curr' : 'next'}`}
          />
        </StoryNavItem>
      ))}
    </StoryNavContainer>
  )
})

StoryNav.displayName = 'StoryNav'

StoryNav.propTypes = {
  index: PropTypes.number.isRequired,
  len: PropTypes.number.isRequired
}

export default StoryNav
