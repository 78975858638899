import styled from '@emotion/styled'
import { colors } from '@styles/theme'

import { cem, mediaMin, sectionTitle } from '@styles/mixins'

export const BackText = styled.span`
  ${sectionTitle({ size: 3, lh: 6.3, color: colors.white }, { size: 1.4, lh: 3, color: colors.white })}
  font-family: ${({ theme }) => theme.fonts.archivo};
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.1em;
  margin-left: ${cem(3)};

  ${mediaMin.sm} {
    font-size: ${cem(2)};
    line-height: ${cem(5.3)};
  }

  ${mediaMin.lg} {
    margin-left: ${cem(1.4)};
    font-size: ${cem(1.4)};
    line-height: ${cem(3)};
  }
`

export const BackIcon = styled.img`
  width: ${cem(1.5)};
  height: auto;
  object-fit: contain;

  ${mediaMin.lg} {
    width: auto;
    height: ${cem(1.2)};
    transition: transform ease-out 0.3s;
  }
`

export const BackContainer = styled.a`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${cem(9)};
  left: ${cem(9)};
  z-index: 1;
  text-decoration: none;

  ${mediaMin.sm} {
    top: ${cem(4.3)};
    left: ${cem(4.3)};
    cursor: pointer;

    &:hover {
      ${BackIcon} {
        transform: translateX(${cem(-0.5)});
      }
    }
  }
`
