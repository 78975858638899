import { gsap } from '@vendor/gsap'

export const animationInit = ({ mediaRef }) => {
  // VIDEO ANIMATION
  const video = mediaRef.current

  gsap.set(video, { y: '100vh' })
}

export const animationAction = ({ mediaRef }) => {
  const tl = gsap.timeline({
    defaults: { duration: 2.7, ease: 'power4.out' }
  })

  tl.add('start')

  // VIDEO ANIMATION
  const video = mediaRef.current

  tl.to(video, { y: 0, duration: 1.5 }, 'start')
}
