import { gsap } from '@vendor/gsap'
import CustomEase from '@vendor/gsap/CustomEase'

export const animationInit = ({ innerRef, wrapperRef }) => {
  const container = innerRef.current
  const wrapper = wrapperRef.current

  gsap.set(container, { y: '100%' })
  gsap.set(wrapper, { y: '100%' })
}

export const animationAction = ({ innerRef, wrapperRef }) => {
  const tl = gsap.timeline({
    defaults: {
      duration: 0.7,
      ease: CustomEase.create('basic', 'M0,0 C0.142,0.43 0.116,0.564 0.372,0.822 0.564,1.002 0.818,1.001 1,1 ')
    }
  })
  tl.add('start')

  const container = innerRef.current
  const wrapper = wrapperRef.current

  tl.to(container, { y: '0' }, 'start')
  tl.to(wrapper, { y: '0' }, 'start+=.1')
}
