import PropTypes from 'prop-types'

import isExternalLink from '@utils/isExternalLink'
import GTM from '@utils/GTM'

import { ButtonWrapperInternal, ButtonWrapperExternal, ButtonLabel } from '@components/Button/style'

const Button = ({ type = 'default', children, title = '', href = '', target = '_self', gtm }) => {
  const Wrapper = isExternalLink(href) ? ButtonWrapperExternal : ButtonWrapperInternal

  const handleClick = () => {
    if (gtm) {
      GTM.push({ ecommerce: null })
      GTM.push(GTM.getSelectPromotionData(gtm))
    }
  }

  return (
    <Wrapper
      to={href}
      href={href}
      className={type}
      title={title || children}
      aria-label={title || children}
      target={target}
      onClick={handleClick}
    >
      <ButtonLabel>{children}</ButtonLabel>
    </Wrapper>
  )
}

Button.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  gtm: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}

export default Button
