import { useParams, ScrollRestoration } from 'react-router-dom'

import { t } from '@locales'

import PageBuilder from '@components/PageBuilder'

import { Container } from '@pages/ModularPage/style'

export default function ModularPage() {
  const { id } = useParams()
  const data = t(`PAGES.${id}`)

  return (
    <Container key={id}>
      <ScrollRestoration
        getKey={(location) => {
          return location.pathname
        }}
      />
      <PageBuilder data={data} />
    </Container>
  )
}
