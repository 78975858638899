import { useEffect, useState } from 'react'

/**
 * Custom Hook triggering on window scroll to init animations
 *
 * @param action {func}
 * @param ref {object}
 * @param limit {number}
 * @param bound {string}
 *
 * @returns {boolean}
 */
export function useAnimator({ init = () => {}, action = () => {}, ref, limit = 0, bound = 'top' }) {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    function handleScroll() {
      const el = ref.current
      const bounds = el.getBoundingClientRect()
      const calc = window.innerHeight - bounds[bound]

      if (calc > limit && !isVisible) {
        setIsVisible(calc > limit)

        action()
      }
    }

    if (!isVisible) {
      init()
      window.addEventListener('scroll', handleScroll)
    }

    return () => window.removeEventListener('scroll', handleScroll)
  }, [ref, isVisible])

  return isVisible
}
