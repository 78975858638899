import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { gsap } from '@vendor/gsap'

import resolveAssetUrl from '@utils/resolveAssetUrl'
import checkTween from '@utils/checkTween'

import StoryNav from '@components/Story/atoms/Nav'

import {
  StorySpecialContainer,
  StorySpecialHeader,
  StorySpecialWrapper,
  StorySpecialItem,
  StorySpecialItemLeft,
  StorySpecialItemRight,
  StorySpecialItemImage
} from '@components/Story/Special/style'
import CustomEase from '@vendor/gsap/CustomEase'
import Observer from '@vendor/gsap/Observer'

const BASE_DURATION = 10000
const BASE_INTERVAL = 1000

const StorySpecial = forwardRef(({ medias = [], onChange = null, paused = false }, ref) => {
  const tweens = useRef({ anim: null, progress: null })
  const navRef = useRef()
  const wrapperRef = useRef()
  const items = useRef([])
  const [transition, setTransition] = useState(false)
  const [index, setIndex] = useState(0)
  const [duration] = useState(BASE_DURATION)

  // PUBLIC METHODS
  useImperativeHandle(ref, () => ({}))

  useEffect(() => {
    if (!paused) {
      setTimer()
    }
  }, [index, paused])

  useEffect(() => {
    const observer = Observer.create({
      target: wrapperRef.current,
      type: 'touch',
      onLeft: () => {
        if (!transition) goTo(index + 1)
      },
      onRight: () => {
        if (!transition) goTo(index - 1)
      },
      preventDefault: false
    })

    return () => {
      observer.kill()
    }
  }, [index, paused, transition])

  useEffect(() => {
    const item = prepare(index)
    if (paused) {
      gsap.set(item.leftImage, { y: '100%' })
      gsap.set(item.rightImage, { y: '-100%' })
      gsap.set(item.leftEl, { backgroundColor: item.leftColor })
      gsap.set(item.rightEl, { backgroundColor: item.rightColor })
    } else {
      checkTween(tweens, 'anim')
      const animationDuration = 0.75

      tweens.anim = gsap.timeline({
        defaults: {
          ease: CustomEase.create('basic', 'M0,0 C0.142,0.43 0.116,0.564 0.372,0.822 0.564,1.002 0.818,1.001 1,1 ')
        },
        onComplete: () => {
          setTransition(false)
        }
      })
      tweens.anim.add('enter')
      tweens.anim.to(
        item.leftImage,
        {
          y: 0,
          duration: animationDuration
        },
        'enter'
      )
      tweens.anim.to(
        item.rightImage,
        {
          y: 0,
          duration: animationDuration
        },
        'enter'
      )
    }
  }, [paused])

  useEffect(() => {
    if (paused) {
      setTransition(true)
    }

    items.current.forEach((item, i) => {
      if (i === index) {
        gsap.set(item, { opacity: 1 })
      } else if (i > index) {
        gsap.set(item, { opacity: 0 })
      } else if (i < index) {
        gsap.set(item, { opacity: 0 })
      }
    })

    return () => {
      checkTween(tweens, 'anim')
      checkTween(tweens, 'progress')
    }
  }, [])

  const setTimer = () => {
    checkTween(tweens, 'progress')

    const item = navRef?.current?.itemsRef?.current[index]

    if (item) {
      tweens.progress = gsap.timeline({
        onComplete: () => {
          if (!transition) goTo(index + 1, BASE_INTERVAL / BASE_INTERVAL)
        }
      })

      tweens.progress.fromTo(
        item,
        {
          width: 0
        },
        {
          width: '100%',
          duration: duration / 1000,
          ease: 'none'
        }
      )
    }
  }

  const goTo = (i, delay) => {
    const oldIndex = index
    const newIndex = i < medias.length ? Math.max(0, i) : 0

    if (oldIndex === newIndex) return

    if (!transition) animationFromTo(oldIndex, newIndex, delay)
  }

  const prepare = (index) => {
    const data = medias[index]
    const el = items.current[index]
    const els = el.querySelectorAll('div')

    return {
      data,
      el,
      leftEl: els[0],
      leftColor: data[0].color,
      leftImage: els[0].querySelector('img'),
      rightEl: els[1],
      rightColor: data[1].color,
      rightImage: els[1].querySelector('img')
    }
  }

  const animationFromTo = (oldIndex, newIndex, delay = 0) => {
    setTransition(true)
    checkTween(tweens, 'anim')
    checkTween(tweens, 'progress')

    const animationDelay = delay
    const animationDuration = 0.75

    const oldItem = prepare(oldIndex)
    const newItem = prepare(newIndex)

    tweens.anim = gsap.timeline({
      defaults: {
        ease: CustomEase.create('basic', 'M0,0 C0.142,0.43 0.116,0.564 0.372,0.822 0.564,1.002 0.818,1.001 1,1 ')
      },
      delay: animationDelay,
      onComplete: () => {
        gsap.set(oldItem.el, { opacity: 0 })
        gsap.set(oldItem.leftEl, { backgroundColor: 'transparent' })
        gsap.set(oldItem.leftImage, { y: 0 })
        gsap.set(oldItem.rightEl, { backgroundColor: 'transparent' })
        gsap.set(oldItem.rightImage, { y: 0 })

        Object.values(navRef?.current?.itemsRef?.current).forEach((j, key) => {
          if (key !== newIndex) {
            if (key > newIndex) {
              gsap.set(j, { width: 0 })
            } else {
              gsap.set(j, { width: '100%' })
            }
          }
        })

        setIndex(newIndex)
        setTransition(false)
        onChange && onChange(newIndex)
      }
    })

    gsap.set(newItem.leftImage, {
      y: newIndex > oldIndex || (newIndex === 0 && oldIndex === medias.length - 1) ? '100%' : '-100%'
    })
    gsap.set(newItem.rightImage, {
      y: newIndex > oldIndex || (newIndex === 0 && oldIndex === medias.length - 1) ? '-100%' : '100%'
    })

    gsap.set(oldItem.el, { zIndex: 0 })
    gsap.set(newItem.el, { zIndex: 1, opacity: 1 })

    gsap.set(oldItem.leftEl, { backgroundColor: newItem.leftColor })
    gsap.set(oldItem.rightEl, { backgroundColor: newItem.rightColor })

    gsap.set(newItem.leftEl, { backgroundColor: 'transparent' })
    gsap.set(newItem.rightEl, { backgroundColor: 'transparent' })

    tweens.anim.add('leave')
    const item = navRef?.current?.itemsRef?.current[index]
    if (item) {
      tweens.anim.to(
        item,
        {
          width: newIndex > oldIndex || (newIndex === 0 && oldIndex === medias.length - 1) ? '100%' : '0%',
          duration: animationDuration
        },
        'leave'
      )
    }

    tweens.anim.to(
      oldItem.leftImage,
      {
        y: newIndex > oldIndex || (newIndex === 0 && oldIndex === medias.length - 1) ? '-100%' : '100%',
        duration: animationDuration
      },
      'leave'
    )
    tweens.anim.to(
      oldItem.rightImage,
      {
        y: newIndex > oldIndex || (newIndex === 0 && oldIndex === medias.length - 1) ? '100%' : '-100%',
        duration: animationDuration
      },
      'leave'
    )

    tweens.anim.add('enter')
    tweens.anim.to(
      newItem.leftImage,
      {
        y: 0,
        duration: animationDuration
      },
      'enter'
    )
    tweens.anim.to(
      newItem.rightImage,
      {
        y: 0,
        duration: animationDuration
      },
      'enter'
    )
  }

  const handleClick = ({ clientX }) => {
    if (!paused) {
      goTo(clientX > window.innerWidth / 2 ? index + 1 : index - 1, 0)
    }
  }

  return (
    <StorySpecialContainer ref={wrapperRef} onClick={handleClick}>
      <StorySpecialHeader>
        <StoryNav ref={navRef} index={index} len={medias.length} />
      </StorySpecialHeader>
      <StorySpecialWrapper>
        {medias.map((m, k) => (
          <StorySpecialItem key={k} ref={(ref) => (items.current[k] = ref)}>
            <StorySpecialItemLeft>
              <StorySpecialItemImage src={resolveAssetUrl(m[0].image.src)} alt={m[0].image.alt} />
            </StorySpecialItemLeft>
            <StorySpecialItemRight>
              <StorySpecialItemImage src={resolveAssetUrl(m[1].image.src)} alt={m[1].image.alt} />
            </StorySpecialItemRight>
          </StorySpecialItem>
        ))}
      </StorySpecialWrapper>
    </StorySpecialContainer>
  )
})

StorySpecial.displayName = 'StorySpecial'

StorySpecial.propTypes = {
  medias: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  paused: PropTypes.bool
}

export default StorySpecial
