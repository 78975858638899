import { useRef } from 'react'
import PropTypes from 'prop-types'

import { useAnimator } from '@hooks/useAnimator'

import Button from '@components/Button'

import resolveAssetUrl from '@utils/resolveAssetUrl'

import {
  SelectionContainer,
  SelectionWrapper,
  SelectionTitle,
  SelectionVisual,
  SelectionBackground,
  SelectionImage,
  SelectionContent,
  SelectionLabel,
  SelectionDescription,
  SelectionActionWrapper
} from '@components/Selection/style'

import { animationAction, animationInit } from '@components/Selection/animations'
import useDeviceDetect from '@hooks/useDeviceDetect'
import Controller from '@utils/Controller'

const Selection = ({ title, label, description, image, action = null }) => {
  const { isDesktop } = useDeviceDetect()
  const containerRef = useRef()
  const titleRef = useRef()
  const textRef = useRef()
  const labelRef = useRef()
  const visualRef = useRef()

  useAnimator({
    init: () => {
      animationInit({ titleRef, visualRef, textRef, labelRef }, isDesktop)
    },
    action: () => {
      animationAction({ titleRef, visualRef, textRef, labelRef }, isDesktop)
    },
    ref: containerRef,
    limit: 0
  })

  return (
    <SelectionContainer ref={containerRef}>
      <SelectionWrapper>
        <SelectionTitle ref={titleRef}>{title}</SelectionTitle>
        <SelectionVisual ref={visualRef}>
          <SelectionImage className={'image'} src={resolveAssetUrl(image.src)} alt={image.alt} />
          <SelectionBackground className={'background'} />
        </SelectionVisual>
        <SelectionContent>
          <SelectionLabel ref={labelRef}>{label}</SelectionLabel>
          <SelectionDescription ref={textRef}>{description}</SelectionDescription>
          {!!action && (
            <SelectionActionWrapper>
              <Button
                {...action}
                href={`${Controller.get('plpUrl')}?cgid=${action?.href}`}
                type={'btn-cta text-white fs--medium ff-normal'}
              >
                {action.label}
              </Button>
            </SelectionActionWrapper>
          )}
        </SelectionContent>
      </SelectionWrapper>
    </SelectionContainer>
  )
}

Selection.propTypes = {
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  action: PropTypes.object
}

export default Selection
