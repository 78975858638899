import { useRef } from 'react'
import PropTypes from 'prop-types'

import resolveAssetUrl from '@utils/resolveAssetUrl'
import GTM from '@utils/GTM'

import { useAnimator } from '@hooks/useAnimator'

import Marquee from '@components/Marquee'

import {
  ListMeetingContainer,
  ListMeetingWrapper,
  ListMeetingTitle,
  ListMeetingList,
  ListMeetingItem,
  ListMeetingItemArrow,
  ListMeetingItemCenter,
  ListMeetingItemLeft,
  ListMeetingItemRight,
  ListMeetingItemContent,
  ListMeetingLabel,
  ListMeetingCity,
  ListMeetingItemFlag
} from '@components/ListMeeting/style'

import { animationAction, animationInit } from '@components/ListMeeting/animations'
import useDeviceDetect from '@hooks/useDeviceDetect'

const ListMeeting = ({ title, list = [], socials = '' }) => {
  const { isDesktop } = useDeviceDetect()
  const containerRef = useRef()
  const titleRef = useRef()
  const listRef = useRef()
  const marqueeRef = useRef()

  useAnimator({
    init: () => {
      animationInit({ listRef, titleRef, marqueeRef }, isDesktop)
    },
    action: () => {
      animationAction({ listRef, titleRef, marqueeRef }, isDesktop)
    },
    ref: containerRef,
    limit: window.innerHeight / 2
  })

  const handleClick = (gtm) => {
    if (gtm) {
      GTM.push({ ecommerce: null })
      GTM.push(GTM.getSelectPromotionData(gtm))
    }
  }

  return (
    <ListMeetingContainer ref={containerRef}>
      <ListMeetingWrapper>
        <ListMeetingTitle ref={titleRef} dangerouslySetInnerHTML={{ __html: title }} />
        {list && list.length && (
          <ListMeetingList ref={listRef}>
            {list.map((i, k) => (
              <ListMeetingItem
                key={k}
                to={i.action.href}
                title={i.action.title}
                onClick={() => handleClick(i.action.gtm)}
                className={'item'}
              >
                <ListMeetingItemLeft className={'left'}>
                  <ListMeetingItemContent>
                    <ListMeetingLabel className={'label'}>{i.items[0].label}</ListMeetingLabel>
                    <ListMeetingCity className={'city'}>{i.items[0].city}</ListMeetingCity>
                  </ListMeetingItemContent>
                  <ListMeetingItemFlag
                    className={'flag'}
                    src={resolveAssetUrl(i.items[0].flag.src)}
                    alt={i.items[0].flag.alt}
                  />
                </ListMeetingItemLeft>
                <ListMeetingItemCenter className={'center'} />
                <ListMeetingItemRight className={'right'}>
                  <ListMeetingItemContent>
                    <ListMeetingLabel className={'label'}>{i.items[1].label}</ListMeetingLabel>
                    <ListMeetingCity className={'city'}>{i.items[1].city}</ListMeetingCity>
                  </ListMeetingItemContent>
                  <ListMeetingItemFlag
                    className={'flag'}
                    src={resolveAssetUrl(i.items[1].flag.src)}
                    alt={i.items[1].flag.alt}
                  />
                </ListMeetingItemRight>
                <ListMeetingItemArrow />
              </ListMeetingItem>
            ))}
          </ListMeetingList>
        )}
        {!!socials && !!socials.length && <Marquee ref={marqueeRef} text={socials} horizontal={true} />}
      </ListMeetingWrapper>
    </ListMeetingContainer>
  )
}

ListMeeting.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.array,
  socials: PropTypes.string
}

export default ListMeeting
