import { createHashRouter } from 'react-router-dom'

// PAGES
import HomePage from '@pages/HomePage'
import ModularPage from '@pages/ModularPage'

const routes = [
  {
    path: '/',
    element: <HomePage />
  },
  {
    path: '/:id',
    element: <ModularPage />
  }
]

const router = createHashRouter(routes)

export default router
