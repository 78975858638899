import { gsap } from '@vendor/gsap'

import { animationTitleAction, animationTitleInit } from '@animations/title'
import { animationTextAction, animationTextInit } from '@animations/text'
import CustomEase from '@vendor/gsap/CustomEase'

export const animationInit = ({ titleRef, visualRef, textRef, labelRef }, isDesktop) => {
  // TITLE ANIMATION
  animationTitleInit(titleRef, isDesktop)
  // TEXT ANIMATION
  animationTextInit(textRef, isDesktop)
  // LABEL ANIMATION
  const label = labelRef.current

  gsap.set(label, { y: '200%', opacity: 0 })

  // VISUAL ANIMATION
  const visual = visualRef.current
  const background = visual.querySelector('.background')
  const image = visual.querySelector('.image')

  gsap.set(background, { y: '100%' })
  gsap.set(image, { y: isDesktop ? '100%' : '200%', opacity: 0 })
}

export const animationAction = ({ titleRef, visualRef, textRef, labelRef }, isDesktop) => {
  const tl = gsap.timeline({
    defaults: { duration: 1.7, ease: 'power4.out' }
  })
  tl.add('start')

  // TITLE ANIMATION
  animationTitleAction(titleRef, isDesktop)
  // TEXT ANIMATION
  animationTextAction(textRef, isDesktop)
  // LABEL ANIMATION
  const label = labelRef.current
  tl.to(label, {
    y: '0',
    opacity: 1,
    ease: CustomEase.create('basic', 'M0,0 C0.142,0.43 0.116,0.564 0.372,0.822 0.564,1.002 0.818,1.001 1,1 ')
  })

  // VISUAL ANIMATION
  const visual = visualRef.current
  const background = visual.querySelector('.background')
  const image = visual.querySelector('.image')

  let ease = CustomEase.create(
    'custom',
    'M0,0 C0.29,0 0.32,0.096 0.34,0.124 0.386,0.19 0.452,0.362 0.484,0.502 0.504,0.592 0.545,0.698 0.6,0.8 0.628,0.834 0.704,1 1,1 '
  )

  tl.to(
    background,
    {
      duration: 0.7,
      y: 0,
      ease
    },
    'start'
  )
  tl.to(
    background,
    {
      duration: 0.7,
      height: 0,
      ease
    },
    'start+=.7'
  )

  tl.to(
    image,
    {
      duration: 0.7,
      y: '0',
      ease
    },
    'start+=.46'
  )
  tl.to(
    image,
    {
      duration: 0.7,
      opacity: 1,
      ease
    },
    'start+=.5'
  )
}
